.header {
	display: flex;
	flex-flow: row nowrap;
	z-index: 1400;
	transition: all 0.3s ease-out, height 0.3s ease-out, box-shadow 0.3s ease-in-out;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	background-color: #fff;
	height: 90px;
	/* box-shadow:0 5px 16px 0 rgba(26,46,74,.15);*/
	border-bottom: 7px solid @cp-color-1;
}

@media (min-width: 640px) {
	.header {
		height: 90px;
	}
}

@media (min-width: 960px) {
	.header {
		height: 160px;
	}
}

.header:focus {
	outline: 0;
}

.header__above-container {
	overflow: visible;
	position: relative;
	z-index: 1401;
	display: none;
}

.header__above-container:after {
	background-color: #00a6e2;
	bottom: -1px;
	content: '';
	display: block;
	left: 50%;
	position: absolute;
	top: 0;
	transform: translateX(-50%);
	width: 100vw;
	z-index: -1;
}

@media (min-width: 960px) {
	.header__above-container {
		display: block;
	}

	.header__above-container:after {
		bottom: 0;
	}
}

.header__container {
	position: relative;
	display: flex;
	flex: 0 1 100%;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-left: 10px;
	padding-right: 0.1px;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

@media (min-width: 720px) {
	.header__container {
	}
}

@media (min-width: 960px) {
	.header__container {
		flex-direction: column;
		align-items: flex-end;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.header__top {
	position: absolute;
	top: 0.5rem;
	right: 20px;
}

.header__bottom,
.header__top {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.header__bottom {
	margin-bottom: 0.5rem;
}

@media (min-width: 1140px) {
	.header__bottom {
		margin-bottom: 1.3rem;
	}
}

.header__nav-meta {
	transition: all 0.3s ease-out, height 0.3s ease-out, box-shadow 0.3s ease-in-out;
	z-index: 1101;
	display: none;
}

@media (min-width: 960px) {
	.header__nav-meta {
		display: block;
	}
}

.header__nav-main {
	transition: all 0.3s ease-out, height 0.3s ease-out, box-shadow 0.3s ease-in-out;
	display: none;
	z-index: 1100;
	width: 100%;
}

@media (min-width: 960px) {
	.header__nav-main {
		display: flex;
		justify-content: flex-end;
	}
}

.header__logo {
	position: absolute;
	left: 20px;
	transition: all 0.3s ease-out, height 0.3s ease-out, box-shadow 0.3s ease-in-out;
	z-index: 1101;

	top: 50%;
	transform: translateY(-50%);
	width: 9rem;

	.logo-primary__image,
	.logo-primary__signet {
		width: 100%;
		height: auto;
	}
}

@media (min-width: 720px) {
	.header__logo {
		width: 180px;
		top: 1rem;
		left: 20px;
		transform: none;
	}
}

@media (min-width: 960px) {
	.header__logo {
		width: 210px;
	}
}

@media (min-width: 1140px) {
	.header__logo {
		top: 2rem;
	}
}

.logo-primary__claim {
	display: none;
	font-size: 12px;
	position: relative;
	background: white;
	color: @text-color;
	margin-top: 0;
	white-space: nowrap;
	word-wrap: none;
}

@media (min-width: 960px) {
	.logo-primary__claim {
		display: block;
	}
}

.header__button {
	margin: 0 15px 0 0;
	padding: 8px;
	color: @text-color;
	background: none;
	text-shadow: none;
	width: 40px;
	height: 40px;
	line-height: 34px;

	.button__text {
		display: none;
	}
}

.header__button:active,
.header__button:focus,
.header__button:hover {
	background: none;
	color: @cp-color-2;
}

.header__button--slide-nav-toggle {
	padding: 0;
}

@media (min-width: 960px) {
	.header__button {
		display: none !important;
	}
}

.header.is-ready {
	position: fixed;
}

.header.is-scrolling-down,
.header.is-scrolling-up {
	// box-shadow:0 5px 16px 0 rgba(26,46,74,.15);
}

.header.will-hide-and-show.is-scrolling-down {
	transform: translateY(-130%);
}

.header.will-hide-and-show.is-scrolling-up {
	transform: translateY(0);
}

.header.is-small {
	// box-shadow:0 5px 16px 0 rgba(26,46,74,.15);
	height: 5.25rem;
	height: 90px;
}

@media (min-width: 720px) {
	.header.is-small {
		height: 6.25rem;
		height: 90px;
	}
}

@media (min-width: 960px) {
	.header.is-small {
		height: 7.25rem;
		height: 120px;
	}
}

.header.is-small .header__logo {
	top: 50%;
	transform: translateY(-50%);
	width: 9rem;
}

@media (min-width: 720px) {
	.header.is-small .header__logo {
		width: 9rem;
	}
}

@media (min-width: 960px) {
	.header.is-small .header__logo {
		width: 210px;

		.logo-primary__claim {
			display: none;
		}
	}
}

@media (min-width: 1200px) {
	.header.is-small .header__logo {
		width: 210px;
	}
}

.header.is-small .header__bottom {
	margin-bottom: 0.4rem;
}

@media (min-width: 1200px) {
	.header.is-small .header__bottom {
		margin-bottom: 0.6rem;
	}
}

.header.is-small .header__nav-meta {
	transform: translateY(-130%);
}
