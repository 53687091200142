.portal {
	position: relative;
}

.themeicon {
	height: 58px;
	width: 58px;
	display: block;
	background-color: @cp-color-1;
	background-size: 58px;
	background-repeat: no-repeat;

	border-radius: 50%;
	
	float: left;
	margin-right: 10px;
	
}

.themeicon--gerechte_chancen {
	background-image: url('@{path}/theme_gerechte_chancen.svg');
}

.themeicon--laendlicher_raum {
	background-image: url('@{path}/theme_laendlicher_raum.svg');
}

.themeicon--kompetent_im_alltag {
	background-image: url('@{path}/theme_kompetent_im_alltag.svg');
}

.themeicon--zukunft_ehrenamt {
	background-image: url('@{path}/theme_zukunft_ehrenamt.svg');
}

.themeicon--landfrauen_und_landwirtschaft {
	background-image: url('@{path}/theme_landfrauen_und_landwirtschaft.svg');
}

.teaser {
	float: left;
	padding: 20px 20px 40px;
	margin-bottom: 20px;

	h3 {
		font: 700 24px/32px @headline-font;
		padding: 0 0 10px;
	}

	.text {
		// overflow: hidden;
		margin: 0;
		padding: 0;
		// position: relative;
		// z-index: 2;
	}

	.image {
		margin: -20px -20px 20px;
	}

	p {
		font: 400 16px/25px @body-font;
		color: @text-color;
		padding: 0 0 10px;
	}

	h5 {
		font: 400 16px/25px @body-font-2;
		color: @text-color;
		padding: 0 0 5px;
	}

	p + h5 {
		padding-top: 10px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0 0 10px;
	}

	ul li {
		font: 400 16px/25px @body-font;
		color: @text-color;
		padding: 0;
	}

	ul li::before {
		display: none;
	}

	ul li a {
		font: 400 16px/25px @body-font;
		color: @text-color;
	}

	.teaser__themeicon {
		position: absolute;
		right: 20px;
		bottom: 20px;
		height: 58px;
		width: 58px;
		display: block;
		background-color: @cp-color-1;
		background-size: 58px;
		background-repeat: no-repeat;

		border-radius: 50%;
		bottom: auto;
		top: 10px;
		right: 10px;
	}

	.teaser__themeicon--gerechte_chancen {
		background-image: url('@{path}/theme_gerechte_chancen.svg');
	}

	.teaser__themeicon--laendlicher_raum {
		background-image: url('@{path}/theme_laendlicher_raum.svg');
	}

	.teaser__themeicon--kompetent_im_alltag {
		background-image: url('@{path}/theme_kompetent_im_alltag.svg');
	}

	.teaser__themeicon--zukunft_ehrenamt {
		background-image: url('@{path}/theme_zukunft_ehrenamt.svg');
	}

	.teaser__themeicon--landfrauen_und_landwirtschaft {
		background-image: url('@{path}/theme_landfrauen_und_landwirtschaft.svg');
	}

	.teaser__badge {
		position: absolute;
		left: 0;
		bottom: 20px;
		background-color: @cp-color-1;
		font: 700 18px/24px @headline-font;
		padding: 5px 15px;
		text-transform: uppercase;
	}

	/*
	.read-more--inline {
		font: 400 ~"14px/20px" @body-font;
		color: @text-color;
		display: inline-block;
		background: url("@{path}/icon_arrow_right_black.svg") right 2px no-repeat;
		background-size: 5px auto;
		text-indent: -1000em;
		width: 10px;

		@media screen and (min-width: 960px )  {

			&:hover, &:focus, &:active {
				background-image: url("@{path}/icon_arrow_right_red.svg");
				color: @cp-color-2;
			}
		}

	}
	*/

	.read-more {
		position: absolute;
		right: 0;
		bottom: 0;
		display: block;
		width: 40px;
		height: 40px;
		color: white;
		background: @cp-color-1;
		text-indent: -1000em;

		.icon-arrow_right;

		&::before {
			position: absolute;
			left: 17px;
			top: 11px;
			font-size: 16px;
			text-indent: 0;
		}

		&:hover {
			color: white !important;
			background: @cp-color-2 !important;
		}
	}

	&.hasButton {
		/*
		.text {
			p {
				display: block;
				padding-bottom: 10px;
			}
		}
		*/
		.button {
			/*
			float: right;
			margin-top: 10px;
			*/
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

@media screen and (min-width: 960px) {
	.teaser {
		margin-bottom: 40px;
	}
}

a.teaser:hover,
a.teaser:focus,
a.teaser:active {
	h3 {
		color: @cp-color-2 !important;
	}

	.read-more {
		color: white !important;
		background: @cp-color-2 !important;
	}
	/*
	.read-more {
		background-image: url("@{path}/icon_arrow_right_red.svg");
		color: @cp-color-2;
	}

	.button {
		background-color: @text-color !important;
	}
	*/
}

/*

.teaser .hover {
	-webkit-transition: background-color 0.3s ease-in-out;
	transition: background-color 0.3s ease-in-out;
	z-index: 1;
}

@media screen and (min-width: 960px )  {

	a.teaser:hover .hover {
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.1);
		position: absolute;
		left: 0;
		top: 0;
	}
}

a.bgYellow {

	@media screen and (min-width: 960px )  {

		&:hover {
			.hover {
				background: rgba(0,0,0,.1);
			}

			h3, .text p, .read-more {
				color: @text-color !important;
			}

			.read-more {
				background-image: url("@{path}/icon_arrow_right_black.svg");
			}

		}
	}
}


*/

.bgRed,
.bgPatrol,
.bgOrange {
	h3,
	h3 a,
	p,
	.read-more {
		color: white;
	}

	h3 a:hover {
		color: @text-color;
	}
}

a.bgRed,
a.bgPatrol,
a.bgOrange {
	&:hover,
	&:focus,
	&:active {
		h3 {
			color: white !important;
			text-decoration: underline;
		}
	}
	/*
	.read-more {
		background-image: url("@{path}/icon_arrow_right_white.svg");
	}

	&:hover {

		.hover {
			background: rgba(0,0,0,.05);
		}

		h3, .text p, .read-more {
			color: @text-color !important;
		}

		.read-more {
			background-image: url("@{path}/icon_arrow_right_black.svg");
		}
	}
	*/
}

a.bgYellow {
	&:hover,
	&:focus,
	&:active {
		h3 {
			color: @text-color !important;
			text-decoration: underline;
		}
	}
	/*
	.read-more {
		background-image: url("@{path}/icon_arrow_right_white.svg");
	}

	&:hover {

		.hover {
			background: rgba(0,0,0,.05);
		}

		h3, .text p, .read-more {
			color: @text-color !important;
		}

		.read-more {
			background-image: url("@{path}/icon_arrow_right_black.svg");
		}
	}
	*/
}

/*
	TEASER XL
*/
.teaserXL {
	// width: 48%;
	// width: 31.33%;
	width: ~'calc(33.333333% - 20px)';
	background: white;
}

@media screen and (min-width: 960px) {
	.teaserXL {
		width: ~'calc(33.333333% - 40px)';
	}
}

.teaserXL .image {
	float: none;
	margin: -20px -20px 20px;
}

.teaserXL h3 {
	font: 700 36px/40px @headline-font;
	padding: 0 0 40px;
}

.teaserXL p {
	font: 400 16px/24px @body-font;
}

/*
	TEASER L
*/
.teaserL {
	//width: 98%;
	width: ~'calc(100% - 20px)';
	background: white;
	float: none;
}

@media screen and (min-width: 960px) {
	.teaserL {
		width: ~'calc(100% - 40px)';
	}
}

.teaserL .image {
	float: none;
	margin: -20px -20px 20px;

	@media screen and (min-width: 960px) {
		float: left;
		/*
		margin: -20px 20px -20px -20px;
		width: 380px;
		width: 33%;
		*/
		margin: -20px 40px -40px -20px;
		width: ~'calc( 33.1555555% - 10px)';
	}
}

.teaserL .text {
	overflow: hidden;
}

.teaserL h3 {
	font: 700 32px/40px @headline-font;
	padding: 0 0 20px;
}

.teaserL p {
	font: 400 16px/24px @body-font;
}

/*
	TEASER M
*/
.teaserM {
	//width: 31.33%;
	width: ~'calc(33.333333% - 20px)';
	background: white;

	.image {
		float: none;
		margin: -20px -20px 20px;

		.credits {
			padding: 2px 10px;
		}

		&:last-child {
			margin: 20px -20px -40px;
			/* border-top: 1px solid #d3d2d3; */
		}
	}
}

@media screen and (min-width: 960px) {
	.teaserM {
		width: ~'calc(33.333333% - 40px)';
	}
}

.teaserPartner {
	.image {
		border: 0 !important;
	}
}

.bloc-320 .teaserM,
.bloc-640 .teaserM,
.bloc-800 .teaserM {
	width: ~'calc(100% - 20px)';
}

@media screen and (min-width: 960px) {
	.bloc-320 .teaserM,
	.bloc-640 .teaserM,
	.bloc-800 .teaserM {
		width: ~'calc(100% - 40px)';
	}
}

@media screen and (min-width: 480px) {
	.bloc-640 .teaserM,
	.bloc-800 .teaserM {
		.image {
			width: 50%;
			max-width: 300px;
			float: left;
			margin: -20px 20px -40px -20px;
		}

		.text {
			overflow: hidden;
		}
	}
}

/*
	TEASER S
 */

img.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.teaserS {
	width: 25%;
	background: transparent;
	margin: 0;
	padding: 0;

	.teaser-box {
		display: block;
		background: #fff;
		width: auto;
		margin: 0 10px 20px;

		@media screen and (min-width: 960px) {
			margin: 0 20px 40px;
		}
	}

	.image-center {
		padding: 0;
		margin: 0;
		position: relative;

		img {
			height: auto;
			max-width: 180px;
		}
	}

	&.supporters {
		.teaser-box {
			min-height: 300px;

			@media screen and (min-width: 960px) {
				&:hover {
					h4 {
						color: #d32e17;

						span {
							background-image: url('../../images/icon_arrow_right_red.svg');
							color: #d32e17;
						}
					}
				}
			}
		}

		figure {
			padding: 20px;
			height: 120px;
			overflow: hidden;
		}

		.text {
			border-top: 1px solid #f2f2f2;
			padding: 10px 20px 20px;
		}
	}
}

/*
	TEASER PORTAL
*/
.teaserPortal {
	// width: 48%;
	//width: 31.33%;
	width: ~'calc(33.333333% - 20px)';
	background: white;
	/* background: @cp-color-1;	*/

	.image {
		float: none;
		margin: -20px -20px 20px;
		z-index: 2;
		position: relative;
	}

	h3 {
		font: 700 36px/40px @headline-font;
		padding: 0 0 20px;
	}

	p {
		font: 400 16px/24px @body-font;
	}
}

@media screen and (min-width: 960px) {
	.teaserPortal {
		width: ~'calc(33.333333% - 40px)';
	}
}

/*

#vipTeaser {
	position: absolute;
	right: 10px;
	top: -20px;
	width: 460px;

	.row {
		float: left;
	}

	.teaser {
		margin: 0;
		width: 50%;
		height: 315px;
		overflow: hidden;

		.image {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 20px;

			img {
				max-width: 180px;
				margin: 0 auto;
				max-height: 180px;
				width: auto;
			}

		}


	}

	.slick-slider {
		margin: 0;
	}

	.slick-prev,
	.slick-next {
		display: none;
	}

	.slick-dots {
		z-index: 1;
		position: absolute;
		bottom: -40px;
		left: 50%;
		margin-left: -21px;

		li {
			width: 10px;
			height: 10px;
			display: inline-block;
			float: left;
			margin: 0 2px;

			button {
				background: #d8d8d8;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				padding: 0;
				text-indent: -10000em;



				@media screen and (min-width: 960px )  {

					&:hover {
						background: @cp-color-2;
					}
				}

				&:before {
					display: none;
				}
			}

			&.slick-active button {
				background: @cp-color-2;
			}
		}
	}

}

*/

/* Teaser Sponsoren */

.teaser.sponsors {
	background-color: transparent;

	.image {
		background-color: transparent;
		margin: 0 0 10px;

		img {
			width: auto;
		}
	}

	.text {
		max-width: 220px;

		p {
			font: 400 12px/16px @body-font;
			display: inline-block;
		}
	}
}

@media screen and (max-width: 959px) {
	.teaser {
		width: ~'calc(50% - 20px)';
		float: none;

		.hover {
			display: none;
			transition: none;
		}
	}

	/*
	#vipTeaser {
		position: relative;
		right: auto;
		top: auto;
		width: auto;

		.teaser {
			width: 50%;
			float: left;
		}

		.image img {
			max-height: 190px;
			width: auto;
			margin: 0 auto;
		}
	}
	*/

	.teaserM {
		min-height: auto !important;
		//height: auto !important;
		box-sizing: border-box;
	}

	.teaserL {
		width: ~'calc(100% - 20px)';

		h3 {
			font: 700 24px/32px @headline-font;
			padding: 0 0 10px;
		}

		/*
		p {
			font: 400 14px/20px @body-font;
			color: #1A171B;
			display: inline;
			padding: 0px;
		}
	*/
	}

	.teaserS {
		width: 50%;
		float: left;
	}

	.teaser {
		&.box {
			margin-bottom: 30px;
		}

		&.teaserHasImage {
			.image {
				width: 33.33%;
				height: auto;
				float: left;
				margin: 0 15px 0 0;
			}

			.text {
				//width: 66.66%;
				//float: right;
			}
		}
	}
}

@media screen and (max-width: 639px) {
	.teaserPortal {
		&.teaserHasImage {
			.image {
				width: auto;
				height: auto;
				float: none;
				margin: -20px -20px 10px;
			}

			.text {
				width: auto;
				float: none;
			}
		}
	}

	.credits {
		display: none !important;
	}
}

@media screen and (max-width: 479px) {
	.teaser {
		width: ~'calc(100% - 20px)';
		margin-bottom: 10px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			word-break: break-word;
		}
	}

	.teaserM,
	.teaserL {
		.text {
			p,
			.read-more {
				// display: none;
			}
		}
	}

	/*
	#vipTeaser {

		.teaser {
			float: none;
			width: auto;
		}
	}
	*/

	.teaserS {
		width: auto;
		float: none;
		margin-bottom: 0;

		&.supporters {
			// min-height: 330px;

			.teaser-box {
				min-height: auto;
			}
		}
	}
}

/*
	TEASER HOME
*/
.teaserHome {
	width: ~'calc(50% - 20px)';
	background: @cp-color-1;

	@media screen and (min-width: 960px) {
		width: ~'calc(50% - 40px)';
	}

	.image {
		float: none;
		margin: -20px -20px 15px;
		z-index: 2;
		position: relative;
	}

	p {
		font: 700 20px/24px @headline-font;
	}
}

.teaserFigures {
	width: ~'calc(100% - 20px)';
	text-align: center;
	margin-bottom: 80px;
	min-height: 580px;

	@media screen and (min-width: 960px) {
		width: ~'calc(50% - 40px)';
	}
}

.teaserNewsletter {
	width: ~'calc(100% - 20px)';
	text-align: center;
	padding: 20px 30px 40px;
	min-height: 580px;

	.image {
		float: none;
		max-width: 140px;
		margin: 20px auto 40px;
		z-index: 2;
		position: relative;
	}

	h2 {
		font: 700 ~'32px/38px' @headline-font;
		color: white;
	}

	p {
		font: 400 16px/24px @body-font;
		padding-bottom: 40px;
	}

	.form {
		text-align: left;
		margin: 0;

		label {
			color: white;
		}

		input {
			border: 0;
		}

		.buttonL span {
			padding-right: 25px;
		}
	}

	.inputgroup {
		display: flex;
	}
}

@media screen and (min-width: 960px) {
	.teaserNewsletter {
		width: ~'calc(50% - 40px)';
		margin-bottom: 80px;
	}
}
