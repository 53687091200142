p,
li {
	font: 400 ~'16px/24px' @body-font;
}

b,
strong {
	font-weight: 700;
	font-family: @body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1 {
	font: 700 36px/44px @headline-font;
	padding: 0 0 40px;
}

h2 {
	font: 700 ~'24px/28px' @headline-font;
}

h3 {
	font: 700 ~'20px/24px' @headline-font;
	padding: 0 0 5px;
}

h4 {
	font: 400 ~'16px/24px' @body-font-2;
	padding: 0 0 5px;
}

h5 {
	font: 400 ~'16px/24px' @body-font;
	padding: 0 0 10px;
}

h6 {
	font: 400 ~'16px/24px' @body-font;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: @text-color;
}

img {
	display: block;
}

hr {
	margin: 0 10px 20px;
	border-color: @cp-color-gray-3;
	float: none;
	clear: both;
	width: auto;
}

hr.trans {
	border-color: transparent;
}

.text ul {
	margin-left: 0;
	overflow: hidden;
}

.text ul li {
	padding-left: 25px;
	margin-left: 0;
	position: relative;
}

.text ul li::before {
	font: 700 ~'28px/20px' @body-font;
	content: '·';
	width: 20px;
	text-align: left;
	display: inline-block;
	position: absolute;
	left: 0;
	top: 2px;
}

.text ol {
	margin-left: 0;
}

.text ol li {
	padding-left: 25px;
	margin-left: 0;
	list-style: none;
	counter-increment: step-counter;
	position: relative;
}

.text ol li::before {
	content: counter(step-counter) '.';
	position: absolute;
	left: 0;
	top: 0;
}

blockquote {
	font: 400 ~'30px/38px' @headline-font;
	padding: 0 0 20px;
}

cite {
	margin: 0;
	padding: 0 0 20px;
	display: block;
	position: relative;
	font: 700 ~'15px/20px' @body-font;
}

blockquote cite {
	margin: 0;
	padding: 0;
}

.quoteRed {
	blockquote {
		color: @cp-color-2;
	}

	cite {
		color: @cp-color-2;
	}
}

.quotePatrol {
	blockquote {
		color: @cp-color-3;
	}

	cite {
		color: @cp-color-3;
	}
}

.credits p,
.mandatory p,
.disclaimer p {
	color: #aaa;
}

table td,
table th {
	padding: 4px 8px;
	border-bottom: 1px solid @cp-color-gray-3;
	border-right: 1px solid @cp-color-white;
}

table th {
	font-weight: 700;
	background: @bg-color;
}

table thead th {
	background: @text-color;
}

iframe {
	width: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

.intro {
	padding: 0 20px 20px 0;
}

.intro p,
.intro li {
	font: 500 ~'24px/34px' @headline-font;
}

.detail {
	padding-bottom: 0;
}

.text p a,
.text li a,
.text dl a,
.text table a {
	text-decoration: none;
}

h1,
h2,
h3,
h6,
.text {
	margin-left: 10px;
	margin-right: 10px;
}

.text h1,
.text h2,
.text h3,
.text h6,
.text hr {
	margin-left: 0;
	margin-right: 0;
}

.box {
	h1,
	h2,
	h3,
	h6,
	.text {
		margin-left: 0;
		margin-right: 0;
	}
}

.bloc-640,
.bloc-800 {
	.text {
		padding-bottom: 20px;
	}
}

@media screen and (min-width: 960px) {
	h1 a:hover,
	h2 a:hover,
	h3 a:hover,
	h4 a:hover,
	h5 a:hover,
	h6 a:hover {
		color: @cp-color-2;
	}

	.text p a,
	.text li a,
	.text dl a,
	.text table a {
		&:hover {
			text-decoration: underline;
		}
	}

	hr {
		margin: 0 20px 40px;
	}
}

@media screen and (max-width: 639px) {
	h6 {
		font: 700 ~'24px/24px' @headline-font;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	h1 {
		font: 700 ~'32px/38px' @headline-font;
	}

	.intro p,
	.intro li {
		font: 500 ~'20px/28px' @headline-font;
	}
}

.quoteSlide h3 {
	padding-bottom: 5px;
}

.blockquote {
	background: @cp-color-white;
	padding: 40px;
	text-align: center;
}

.blockquote__image {
	width: 80px;
	margin: 0 auto 15px;

	img {
		border-radius: 50%;
		width: 100%;
		height: auto;
	}
}

.blockquote--yellow {
	background: @cp-color-1;
}

.blockquote--red {
	color: @cp-color-white;
	background: @cp-color-2;

	blockquote {
		color: @cp-color-white;
	}
}

.blockquote--patrol {
	color: @cp-color-white;
	background: @cp-color-3;

	blockquote {
		color: @cp-color-white;
	}
}

.quote-slick {
	padding: 0;
	margin-bottom: 40px;

	.media {
		margin: 0;
		padding: 0;
	}

	.slick-slider {
		margin: 0;
	}

	.slick-prev,
	.slick-next {
		height: 40px;
		width: 40px;
		margin: -20px 0 0;
		background: @cp-color-1;
		position: absolute;
		top: 50%;
		display: block;
		cursor: pointer;
		color: @cp-color-white;
		text-align: center;
		line-height: 40px;

		i {
			display: block;
			position: relative;
			width: 40px;
			height: 40px;
			color: @cp-color-white;

			&::before {
				font-size: 22px;
			}
		}

		span {
			text-indent: -9999em;
			display: block;
			visibility: hidden;
		}

		&:hover {
			color: @cp-color-white;
			background: @cp-color-2;

			i {
				color: @cp-color-white;
			}
		}

		&::before {
			display: none;
		}
	}

	.slick-prev {
		left: -20px;

		i {
			.icon-arrow_left;
		}
	}

	.slick-next {
		right: -20px;

		i {
			.icon-arrow_right;
		}
	}

	.slick-dots {
		z-index: 1;
		position: absolute;
		bottom: -30px;
		left: 50%;
		transform: translateX(-50%);
		margin: 0;

		li {
			width: 15px;
			height: 15px;
			display: inline-block;
			float: left;
			margin: 0 7px;

			button {
				background: @cp-color-white;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				padding: 0;
				text-indent: -10000em;

				&:hover {
					background: @cp-color-2;
				}

				&::before {
					display: none;
				}
			}

			&.slick-active button {
				background: @cp-color-1;
			}
		}
	}
}
