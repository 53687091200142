@font-face {
	font-family: 'Roboto Bold';
	src: url('../../statics/fonts/Roboto/Roboto-Bold-webfont.woff2') format('woff2'),
		url('../../statics/fonts/Roboto/Roboto-Bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Bold';
	src: url('../../statics/fonts/Roboto/Roboto-BoldItalic-webfont.woff2') format('woff2'),
		url('../../statics/fonts/Roboto/Roboto-BoldItalic-webfont.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../statics/fonts/Roboto/Roboto-Italic-webfont.woff2') format('woff2'),
		url('../../statics/fonts/Roboto/Roboto-Italic-webfont.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../statics/fonts/Roboto/Roboto-Regular-webfont.woff2') format('woff2'),
		url('../../statics/fonts/Roboto/Roboto-Regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../statics/fonts/Roboto/Roboto-Bold-webfont.woff2') format('woff2'),
		url('../../statics/fonts/Roboto/Roboto-Bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../statics/fonts/Roboto/Roboto-BoldItalic-webfont.woff2') format('woff2'),
		url('../../statics/fonts/Roboto/Roboto-BoldItalic-webfont.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}