.pagination {
	}

.pagination ul {
	float: right;
	margin: 0;
	}
	
.pagination ul li {
	list-style: none;
	float: left;
	margin: 0 0 0 1px;
	line-height: 40px;
	}

.pagination ul li.points span {
	display: block;
	text-align: center;
	font: 400 14px/40px @body-font;
	color: @text-color;
	}
.pagination ul li span,	
.pagination ul li a {
	display: block;
	height: 40px;
	min-width: 20px;
	padding: 0 10px;
	background: white;
	overflow: hidden;
	text-align: center;
	font: 400 14px/40px @body-font;
	color: @text-color;
	}
	
.pagination ul li a:hover {
	background: @cp-color-1;
	}

.pagination ul li.active span,
.pagination ul li.active a,
.pagination ul li a.active {
	/* font-weight: 700; */
	font-family: @body-font-2;
	background: @cp-color-1;
	}

.pagination ul li.prev a,
.pagination ul li.next a {
	width: 20px;
	padding: 0;
	text-align: left;
	text-indent: -10000em;
	background: url("@{path}/arrows.png") 5px -498px no-repeat;
	display: none;
}
.pagination ul li.prev a:hover {
	background-position: 6px -598px;
}
.pagination ul li.next a {
	background-position: -3px 2px;
}
.pagination ul li.next a:hover {
	background-position: -3px -98px;
}



/*
.pagination p {
	float: left;
	font: 400 13px/20px @body-font;
	color: #919193;
	padding: 0;
	}
	
.currentPage,
.totalPages {
	font-weight: 700;
}
	
.pagination .entries {
	}

.startEntry,
.endEntry,
.totalEntries {
	font-weight: 700;
}

.pagination .disabled {
	visibility: hidden;
}

.pagination ul li.prev a,
.pagination ul li.next a {
	width: 20px;
	padding: 0;
	text-align: left;
	text-indent: -10000em;
	background: url("@{path}/arrows.png") 5px -498px no-repeat;
}
.pagination ul li.prev a:hover {
	background-position: 6px -598px;
}
.pagination ul li.next a {
	background-position: -3px 2px;
}
.pagination ul li.next a:hover {
	background-position: -3px -98px;
}



.pagination.back a,
.pagination .back {
	padding: 0 0 0 12px;
	background: url("@{path}/icon_arrow_left.svg") left 8px no-repeat;
	background-size: 5px 12px;
	font: 400 13px/30px @body-font;
	display: inline-block;
	}

.pagination.back a:hover,
.pagination .back:hover {
	color: @text-color;
	background: url("@{path}/icon_arrow_left_black.svg") left 8px no-repeat;
	background-size: 5px 12px;
	}
*/


	
	
	
@media screen and ( max-width: 959px )  {

	
}

@media screen and ( max-width: 639px )  {

	.boxA .paginationTop {
		margin: -10px -15px 25px;
	}
	
	.boxA .paginationBottom {
		margin: 25px -15px -10px;
	}

}