/* STRUCTURE */

body {
	background: @bg-color;
	margin: 0;
	min-width: @page-width;
	overflow-x: hidden;
}

#header {
	position: relative;
	background: @cp-color-white;
}

#header .wrap {
	min-height: 157px;
}

.page {
	margin-top: 97px;

	@media screen and (min-width: 640px) {
		margin-top: 97px;
	}

	@media screen and (min-width: 960px) {
		margin-top: 167px;
	}
}

#content .wrap {
	padding: 80px 0 55px;
	min-height: 320px;
}

#footer {
	position: relative;
	background: @cp-color-white;
}

#footer .wrap {
	padding: 0 10px;
	box-sizing: border-box;
}

@media screen and (min-width: 960px) {
	#footer .wrap {
		padding: 0 20px;
	}
}

.bloc-800 {
	width: 800px;
	float: left;
}

.bloc-640 {
	width: 640px;
	float: left;
}

.bloc-480 {
	width: 480px;
	float: left;
}

.bloc-320 {
	width: 320px;
	float: left;
}

.bloc-25p {
	width: 25%;
	float: left;
}

.bloc-33p {
	width: 33%;
	float: left;
}

.bloc-50p {
	width: 50%;
	float: left;
}

.bloc-67p {
	width: 67%;
	float: left;
}

.bloc-75p {
	width: 75%;
	float: left;
}

.bloc-100p {
	width: 100%;
	float: left;
}

.box {
	margin: 0 10px 40px;
	display: block;
	position: relative;
	box-sizing: border-box;
}

.bloc-640 .box,
.bloc-800 .box,
.bloc-960 .box {
	margin: 0 10px 40px;
}

.bloc-320 .box,
.bloc-480 .box {
	float: left;
	margin: 0 10px 20px;
}

@media screen and (min-width: 960px) {
	.bloc-800 {
		width: 87.5%;
	}

	.bloc-640 {
		width: 66.666667%;
	}

	.bloc-480 {
		width: 50%;
	}

	.bloc-320 {
		width: 33.333333%;
	}

	.box,
	.bloc-640 .box,
	.bloc-800 .box,
	.bloc-960 .box,
	.bloc-320 .box,
	.bloc-480 .box {
		margin: 0 20px 40px;
	}

	// Contents
	h1,
	h2,
	h3,
	h6,
	.text {
		margin-left: 20px;
		margin-right: 20px;
	}

	h1 {
		font: 700 ~'40px/48px' @headline-font;
	}

	h2 {
		font: 700 28px/36px @headline-font;
	}

	h3 {
		font: 700 24px/32px @headline-font;
		text-transform: none;
	}

	h4 {
		font: 700 18px/26px @headline-font;
	}

	p,
	li,
	dl {
		font: 400 17px/30px @body-font;
	}

	p {
		padding: 0 0 40px;
	}

	ul,
	ol,
	hr {
		margin-bottom: 40px;
	}

	// anchor
	.anchors ul {
		margin-left: 20px;
	}
}

@media screen and (max-width: 959px) {
	body {
		min-width: 640px;
	}

	.wrap {
		width: 640px;
	}

	.bloc-800,
	.bloc-480,
	.bloc-320 {
		width: 640px;
	}

	.bloc-480 .box {
		width: 300px;
	}

	.bloc-800 .box,
	.bloc-320 .box,
	.bloc-480 .box {
		float: none;
		margin: 0 10px 40px;
	}

	#header {
		.wrap {
			min-height: 140px;
		}
	}

	#content .wrap,
	#footer .wrap {
		padding: 30px 0;
	}

	.bloc-25p,
	.bloc-33p,
	.bloc-50p,
	.bloc-67p,
	.bloc-75p,
	.bloc-100p {
		width: auto;
		float: none;
	}
}

@media screen and (max-width: 639px) {
	body {
		min-width: 320px;
		background-position: center -130px;
	}

	#header .wrap {
		min-height: 105px;
	}

	#content .wrap {
		padding: 20px 0 0;
	}

	.wrap,
	.bloc-800,
	.bloc-640,
	.bloc-480,
	.bloc-320 {
		width: 100%;
		margin: 0;
		float: none;
	}

	.box {
		margin: 0 10px 40px;
	}

	.bloc-800 .box,
	.bloc-320 .box,
	.bloc-480 .box,
	.bloc-640 .box {
		float: none;
		width: auto;
	}

	#footer {
		#address strong,
		#address p,
		#service {
			display: block;
			width: auto;
			float: none;
			border: none;
			min-height: 0;
			padding: 0;
			margin-bottom: 20px;
			padding: 15px;
			border-bottom: 1px solid #e6e6e6;
		}

		#service {
			border: none;
		}
	}
}

@media screen and (max-width: 479px) {
	body {
		min-width: 320px;
		background-position: center -70px;
	}
}

@media print {
	body {
		overflow: visible;
	}
}
