.listL {
	border-top: 1px solid #d3d2d3;
	padding: 20px 0;
	margin: 0 10px !important;
	width: ~'calc(100% - 20px)';
	}


@media screen and (min-width: 960px) {
	.listL {
		margin: 0 20px !important;
		width: ~'calc(100% - 40px)';
	}
}

.listL .image {
	width: 220px;
	margin: 0 20px 20px 0;
	}
	
@media screen and (min-width: 640px) {
	
	.listL .image {
		margin: 0 20px 10px 0;
		float: left;
	}
}

.listL h6 {
	font: 400 ~"14px/20px" @body-font-2;
	padding: 0 0 5px;
}
.listL h2 {
	font-size: 24px;
	line-height: 32px;
	padding: 0 0 15px;
}
.listL p {
	display: block;
	padding: 0 0 10px;
}

.listL .read-more {
	text-indent: 0;
	width: auto;
	padding-right: 12px;
}