/*

#newsDetail .pagination {
	margin-bottom: 30px;
	}

#newsDetail h1 {
	padding: 0 0 40px;
	}

#newsDetail .notice h1 {
	padding: 0 0 20px;
	}
	
#newsDetail h6 {
	font: 400 13px/20px @body-font;
	padding: 0 0 5px;
	margin: 0 10px;
	color: @text-color;
	text-transform: none;
	left: auto;
	background: transparent;
	}

#newsDetail span,
#newsDetail date, {
	
	&:before {
		content: "·";
		width: 30px;
		text-align: center;
		display: inline-block;
		color: @text-color !important;
		margin-left: -2px;
	}
	
	&:first-child:before {
		display: none;
	}
}

#newsDetail .text {
	padding-bottom: 20px;
	}

#newsDetail .fact .text,
#newsDetail .accordeon .text {
	padding-bottom: 0;
	}

*/






#newsList h3 {
	/*
	border-top: 5px solid @text-color;
	padding: 5px 0;
	font: 400 ~"20px/24px" @headline-font;
	text-transform: uppercase;
	*/
	padding: 0 0 10px;
	font: 700 24px/32px @headline-font;
	}

#newsList .month {
	position: relative;
	padding-bottom: 20px;
}
	
#newsList .top {
	position: absolute;
	top: 12px;
	right: 20px;
	margin: 0;
	color: @text-color;
	text-transform: lowercase;
	font-size: 14px;
	
	&:hover, &:focus, &:active {
		color: @cp-color-2;
	}
		
}

#newsList .teaser .read-more {
	display: none;
}

.news .teaser {
	float: none;
	
	&:hover {
		h2 { color: @cp-color-2; }
	}
	
}

.event-sign-in {
	background-color: #D32E17;
	color: #FFF;
	margin-left: 10px;
	padding: 0px 5px;
	border-radius: 3px;
	line-height: 21px;
	display: inline-block;
	font-size: 12px;
	float: right;

	&:hover {
		color: #fff;
		background: #000;
	}
}

@media screen and ( max-width: 479px )  {

	.news {

		.teaser {

			.text {
			
				p, .read-more {
					display: none;
				}
			}
		}
	}
}
