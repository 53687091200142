@import '../core/config.less';

.error404 {
	background: #ebbd00;
	overflow: hidden;

	.wrap {
		height: 485px;
		padding-top: 15px;
	}

	h1 {
		margin-bottom: 25px;
	}

	h2 {
		margin-bottom: 15px;
	}

	a {
		font: 400 14px/20px 'Roboto Regular', Arial;
		color: @text-color;
		position: relative;
		padding-right: 15px;
		display: inline-block;

		.icon-arrow_right;

		&::before {
			position: absolute;
			right: 0;
			font-size: 12px;
		}

		&:hover {
			color: @cp-color-2;
		}
	}

	.haseimeimer {
		height: 480px;
		width: 414px;
		position: absolute;
		bottom: 0;
		right: 0;

		img {
			position: absolute;
			bottom: 0;
		}

		.eimer {
			left: 20px;
			bottom: -50px;
			z-index: 2;
		}

		.hase {
			left: -16px;
			bottom: 26px;
			opacity: 0;
			z-index: 1;
		}
	}
}

@media screen and (max-width: 959px) {
	.error404 {
		.haseimeimer {
			height: 240px;
			width: 220px;

			.eimer {
				width: 200px;
				height: 180px;
				left: 20px;
				bottom: -30px;
			}

			.hase {
				width: 175px;
				height: 223px;
				left: 0;
				bottom: 9px;
			}
		}
	}
}
