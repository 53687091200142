@media all {
	.grid {
		display: table;
		table-layout: fixed;
		width: 100%;
		list-style-type: none;
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}

	/* muss hinter .grid stehen, dann können beide Klassen zusammen in einer Div verwendet werden. */
	.wrap {
		width: @page-width;
		margin: 0 auto;
		position: relative;
	}

	.bloc-1,
	.bloc-2,
	.bloc-3,
	.bloc-4,
	.bloc-5,
	.bloc-6,
	.bloc-7,
	.bloc-8,
	.bloc-9,
	.bloc-10,
	.bloc-11,
	.bloc-12,
	.bloc-13,
	.bloc-14,
	.bloc-15,
	.bloc-16 {
		float: left;
		margin: 0;
	}

	.bloc-1 {
		width: (@page-width);
	}

	.bloc-2 {
		width: (@page-width / 2);
	}

	.bloc-3 {
		width: (@page-width / 3);
	}

	.bloc-4 {
		width: (@page-width / 4);
	}

	.bloc-5 {
		width: (@page-width / 5);
	}

	.bloc-6 {
		width: (@page-width / 6);
	}

	.bloc-7 {
		width: (@page-width / 7);
	}

	.bloc-8 {
		width: (@page-width / 8);
	}

	.bloc-9 {
		width: (@page-width / 9);
	}

	.bloc-10 {
		width: (@page-width / 10);
	}

	.bloc-11 {
		width: (@page-width / 11);
	}

	.bloc-12 {
		width: @page-width / 12;
	}

	.bloc-13 {
		width: (@page-width / 13);
	}

	.bloc-14 {
		width: (@page-width / 14);
	}

	.bloc-15 {
		width: (@page-width / 15);
	}

	.bloc-16 {
		width: (@page-width / 16);
	}

	.box {
		padding: 0;
		margin: 0;
	}
}

@media screen and (min-width: 960px) {
	.wrap {
		width: auto;
		max-width: 1240px;
	}
}
/*
@media screen and ( min-width: 1240px )  {

	.wrap {
		min-width: 1240px;
	}
}
*/

@media print {
	/* Floats in Grids einschließen. Verwendet display:table, um Darstellungsprobleme im FF & IE zu vermeiden */
	.grid > .bloc-1,
	.grid > .bloc-2,
	.grid > .bloc-3,
	.grid > .bloc-4,
	.grid > .bloc-5,
	.grid > .bloc-6,
	.grid > .bloc-7,
	.grid > .bloc-8,
	.grid > .bloc-9,
	.grid > .bloc-10,
	.grid > .bloc-11,
	.grid > .bloc-12,
	.grid > .bloc-13,
	.grid > .bloc-14,
	.grid > .bloc-15,
	.grid > .bloc-16 {
		overflow: visible;
		display: table;
		table-layout: fixed;
	}
}
