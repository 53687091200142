
.filter h4 {
	font: 400 18px/24px @body-font;
	padding: 0 0 15px;
}
	
.filter .item {
	padding: 0 10px 10px 0;
	margin: 0;
	float: left;
	
	&:last-child {
		padding-right: 0;
	}
}
.filter .item30 { width: 150px; }
.filter .item40 { width: 300px; }
	


.filter {
	padding: 0;
	margin: 0;
	
	.select2-container .select2-choice,
	.select2-container-multi .select2-choices {
		font: 400 16px/18px @body-font;
		box-shadow: none !important;
		color: @text-color !important;
		border: 1px solid white;
		height: 18px;
	}
	
	.select2-container .select2-choice .select2-arrow {
		width: 20px;
		right: 7px;
		top: 1px;
		
		b {
			.icon-arrow_down;
			&:before {
				font-size: 5px;
			}
		
		}
		
	}
	
	.select2-container .select2-choice:hover,
	.select2-container-multi .select2-choices:hover {
		color: @text-color;
		border: 1px solid @text-color !important;
	}
	
	/*
	.select2-container .select2-choice:hover .select2-arrow b {
		background: url("@{path}/icon_arrow_down_black.svg") center center no-repeat;
		background-size: 12px 5px;
	}
	*/
	
}


	

@media screen and ( max-width: 959px )  {

	
	
}

@media screen and ( max-width: 639px )  {
	
	.filter .item30,	
	.filter .item30 .select2-container,
	.filter .item40,
	.filter .item40 .select2-container {
		width: 100%;
		}
			
}