#socials {
	right: 0;
	position: fixed;
	z-index: 1500;
	top: 50%;
	transform: translateY(-50%);
}

#socials ul {
	padding: 0;
	width: 46px;
	margin: 0;
	}
	
#socials li {
	list-style: none;
	margin: 0 0 1px;
	}
	
#socials li a {
	display: block;
	width: 46px;
	height: 46px;
	text-indent: -10000em;
	background-color: @cp-color-2;
	background-repeat: no-repeat;
	background-position: center center;
	}


@media screen and (min-width: 960px )  {

	#socials li a:hover {
		background-color: @text-color;
	}
}

#socials li a.facebook {
	background-image: url("@{path}/icon_facebook.svg");
	background-size: 12px auto;
	}
	
#socials li a.youtube {
	background-image: url("@{path}/icon_youtube.svg");
	background-size: 28px auto;
	}
	
#socials li a.twitter {
	background-image: url("@{path}/icon_twitter.svg");
	background-size: 32px auto;
	}
	
#socials li a.instagram {
	background-image: url("@{path}/icon_instagram.svg");
	background-size: 36px auto;
	}
/*
#socials {
	width: 150px;
	margin: 0 auto;
	display: block;
	clear: both;
	top: -30px;
	position: relative;
}
	
#socials ul {
	padding: 0;
	}
	
#socials li {
	list-style: none;
	margin: 0 5px;
	float: left;
	}
	
#socials li a {
	display: block;
	width: 40px;
	height: 40px;
	text-indent: -10000em;
	background-repeat: no-repeat;
	background-position: center center;
	}

#socials li a.issue {
	background-image: url("@{path}/icon_sm_issuu.svg");
	background-size: 30px 30px;
	}
#socials li a.issue:hover {
	background-image: url("@{path}/icon_sm_issuu_black.svg");
	background-size: 30px 30px;
	}
	
#socials li a.flickr {
	background-image: url("@{path}/icon_sm_flickr.svg");
	background-size: 60% auto;
	}
#socials li a.flickr:hover {
	background-image: url("@{path}/icon_sm_flickr_black.svg");
	background-size: 60% auto;
	}
#socials li a.rss {
	background-image: url("@{path}/icon_sm_rss.svg");
	background-size: 22px 22px;
	}
#socials li a.rss:hover { 
	background-image: url("@{path}/icon_sm_rss_black.svg");
	background-size: 22px 22px;
	}

#socials li a:before {
	opacity: 0;
	content: '';
	transition: opacity 0.75s ease-in-out;
	-moz-transition: opacity 0.25s ease-in-out;
	-webkit-transition: opacity 0.25s ease-in-out;
}

#socials li a:hover {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		width: 100px;
		text-align: center;
		height: auto;
		top: -20px;
		text-indent: 0;
		left: 50%;
		margin-left: -50px;
		color: #000;
		font: 400 16px/20px "FFFagoWebBold",Arial;
		text-transform: uppercase;
		background: transparent;
		opacity: 1;
	}

	&.issue:before {
		content: 'Issuu';
	}

	&.flickr:before {
		content: 'Flickr';
	}

	&.rss:before {
		content: 'Rss';
	}
} 

*/


@media screen and ( max-width: 959px )  {
	
	#socials {
		top: 0;	
		display: none;
	}
}

@media screen and ( max-width: 639px )  {

	/* TODO
	#socials {
		position: absolute;
		right: 117px;
		top: 46px;

		ul {
			width: 112px;

			li {
				float: left;
				margin: 0px 10px 0 0;
			}
		}
	}
	*/
}
