/*
.latest {
	background: white;
	float: left;
	padding: 15px 15px 40px;
	width: 460px;
	margin: 0 10px 20px;
}

.latest h4 {
	text-transform: uppercase;
	font: 400 ~"20px/24px" @headline-font;
	padding: 0 0 20px;
}

.latest a.read-all {
	position: absolute;
	top: 15px;
	right: 15px;
	font: 400 ~"13px/24px" @body-font;
	color: @text-color;
	z-index: 2;
	padding: 0 10px 0 0;
	
	.icon-arrow_right;
	
	&:before {
		color: @cp-color-2;
		font-size: 10px;
		right: 0;
		position: absolute;
		top: 0;
	}
	
	&:hover {
		color: @cp-color-2;
	}
}

.latest .item {
	background: white;
}

.latest .item h3 {
	//padding-left: 155px;
}

.latest .item:hover h3 {
	color: @cp-color-2;
}


#latestNews .date {
	color: @text-color !important;
	font: 400 ~"12px/20px" @body-font-2;
}

#latestNews {
	
	.image {
		width: 140px;
		height: 105px;
		float: left;
		background: grey;
		margin: 7px 0 0 0;
		
		img {
			width: 100%;
			height: auto;
		}
		
	}
	
	.text {
		padding: 0 15px;
		overflow: hidden;
	}
}


#upcomingEvents {
	
	.item {
		min-height: 75px;
		padding-bottom: 10px;
		display: block;
		
		&:last-child {
			padding: 0;
		}
	}
	
	.location {
		color: @text-color !important;
		font: 400 ~"14px/20px" @body-font-2;
	}
	
	.date {
		width: 140px;
		height: 68px;
		float: left;
		background: @cp-color-2 url("@{path}/bg_upcomingEvents.png") left top repeat-x;
		margin: 0 15px 0 0;
		padding: 8px 0 0;
		box-sizing: border-box;
	}
	
	.day, .month {
		display: block;
		text-align: center;
		color: white;
	}
	
	.day {	
		font: 400 ~"36px/36px" @headline-font;
	}
	.month {
		font: 400 ~"14px/20px" @body-font-2;
	}
	
}
*/

.tabs__panel {

	.slick-slider {
	}
	
	.slick-prev,
	.slick-next {
		display: none;
	}

	.slick-dots {
		z-index: 1;
		position: absolute;
		bottom: -23px;
		/*
		left: 50%;
		transform: translateX(-50%);
		*/
		margin: 0;	
		display: flex !important;
	    justify-content: center;
	    width: 100%;
		
	
		li {
			width: 15px;
			height: 15px;
			display: inline-block;
			margin: 3px 6px;
	
			button {
				background: #d8d8d8;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				padding: 0;
				text-indent: -10000em;
				
				&:hover {
					background: @cp-color-2;
				}
				
				&:before {
					display: none;
				}
			}
	
			&.slick-active button {
				background: @cp-color-1;
			}
		}
	}
/*	
	.slick-dots {
		z-index: 1;
		position: absolute;
		bottom: -46px;
		left: 50%;
		margin-left: -21px;
	
		li {
			width: 10px;
			height: 10px;
			display: inline-block;
			float: left;
			margin: 0 2px;
	
			button {
				background: #d8d8d8;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				padding: 0;
				text-indent: -10000em;
				
				&:hover {
					background: @cp-color-2;
				}
				
				&:before {
					display: none;
				}
			}
	
			&.slick-active button {
				background: @cp-color-2;
			}
		}
	}
*/
}
