.typo3-message {
	margin: 0px 20px 40px 10px;
	padding: 25px 25px 25px;
	background: #EBBD00 none repeat scroll 0% 0%;
	display: block;
	position: relative;
	min-height: 150px;
	box-sizing: border-box;

	.message-header {
		font: 400 24px/28px "Bree Serif Regular",Arial;
		margin: 0px;
		padding: 10px 0px;
		color: #1A171B;
	}

	.message-body {
		font: 400 16px/24px "Roboto Regular",Arial;
		color: #1A171B;
	}

	&.message-ok,
	&.message-error,
	&.message-info,
	&.message-notice,
	&.message-warning {
		padding-left: 150px;
		
		&:before {
			content: "";
			position: absolute;
			left: 25px;
			width: 100px;
			height: 100px;
			border-radius: 50px;
			background-color: #FFF;
		}
	}

	&.message-ok {
		
		&:before {
			background: #FFF url("../../images/icon_process_ok.svg") no-repeat scroll center center / 50px auto;
		}
	}

	&.message-error {
		
		&:before {
			background: #FFF url("../../images/icon_response_error.svg") no-repeat scroll center center / 50px auto;
		}
	}

	&.message-info,
	&.message-notice,
	&.message-warning {
		
		&:before {
			background: #FFF url("../../images/icon_response_info.svg") no-repeat scroll center center / 10px auto;
		}
	}
}

@media screen and ( max-width: 479px )  {

	.typo3-message.message-ok::before, .typo3-message.message-error::before, .typo3-message.message-info::before, .typo3-message.message-notice::before, .typo3-message.message-warning::before {
		display: none;
	}

	.typo3-message.message-ok, .typo3-message.message-error, .typo3-message.message-info, .typo3-message.message-notice, .typo3-message.message-warning{
		padding-left: 20px;
	}
}