.team {
	float: left;
	background: white;
	width: ~"calc(100% - 20px)" !important;
	padding: 20px 20px 0;
}


.team h2 {
	/*
	border-top: 5px solid @text-color;
	padding: 5px 0;
	font: 400 ~"20px/24px" @headline-font;
	text-transform: uppercase;
	*/
	padding: 0 0 20px;
	font: 700 24px/32px @headline-font;
	}

.team p {
	font: 400 15px/22px @body-font;
	padding: 0 0 20px;
	}
	
.team h2 + p {
	margin-top: -15px;
	}
	
	
.team .person {
	position: relative;
	padding-bottom: 20px;
}

.team .image {	
	float: left;
	width: 140px;
	
	width: 100px;
	margin: 0 20px 20px 0;
	}

.team .image img {
	width: 100%;
	height: auto;
	}
    
    .team h4 {
    	padding: 0;
		font: 400 16px/24px @body-font-2;
	}
	
.team .item {
	display: block;
	}
	
.team .text {
	overflow: hidden;
	font: 400 14px/20px @body-font;
	color: @text-color;
	padding: 0;
	}

.team .text p {
    font: 400 15px/22px @body-font;
	padding: 0 0 10px;
	border: 0;
	}

.team .text .position {
	color: #9FA0A0;
	}
.team .text .organisation {
	color: #9FA0A0;
	}
	/*
.team .text .position {
	font-style: italic;
	padding-bottom: 20px;
	#9FA0A0
	}
.team .organisation {
	font-family: @body-font-2;
	}
	*/
	/**/
.team .email a,	
.team .url a,	
.team a.phone,
.team a.fax {
	font-family: @body-font;
	color: @text-color;
	text-decoration: none !important;
	
	&:hover {
		color: @cp-color-2 !important;
	}
}


/*

@media screen and ( min-width: 979px )  { 
	
	.bloc-640 { 
		.team {
			float: none;
		}
	}
	
	.bloc-800 { 
		.team {
			float: none;
		}
	}
	
	.bloc-320 { 
		
		
		.team .image {	
			position: absolute;
			right: 0;
			top: 0;
			margin: 0;
			
			float: right;
			margin: -11px 0 10px 15px;
			width: 80px;
		}
		
		.team .text {
			padding: 0 15px;
			overflow: visible;
			
			p {
				padding: 0 0 10px;
			}
		}
		
		.team h4 {
			font-size: 15px;
		}
	
	}
}

@media screen and ( max-width: 979px )  { 
	
	.bloc-320 .team {
		
		.image {	
			position: relative;
			right: auto;
			top: auto;
			margin: 0 20px 0 0;
			width: 140px;
		}
		
		.text {
			padding-right: 0;
		}
		
		h4 {
			padding: 15px 0 5px;
			font-size: 18px;
		}
	}
	
}

@media screen and ( max-width: 639px )  { 

	.team {
		float: none;
		margin-bottom: 20px !important;
		
		.text {
			overflow: hidden;
		}
		
		h4 {
			padding: 5px 0 0 !important;
		}
		
		p {
			padding: 0 0 5px;
		}
		
		
		.image {
			margin: 0 10px 0 -10px !important;
			width: 100px !important;
		}
	}
	
}

*/

@media screen and ( min-width: 960px )  {
	
	
	.team {
		width: ~"calc(100% - 40px)" !important;
	}
}