#headline {
	position: relative;
	background-color: @cp-color-1;
	}
	
#headline .wrap {
	min-height: 235px;
	background-position: right center;
	background-repeat: no-repeat;
	}

/*
.randomContent {

	& > * {
		position: absolute;
		left: -10000em;
	}
	
	.js-randomContent__show {
		position: absolute;
		left: 50%;
		width: 100%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	
}
*/
.headline--statement {  
	/*  
	background-image: url("/frontend/assets/images/teaserM-03.jpg");
    background-size: cover;
	*/
	// border-top: 7px solid #ebbd00;
	overflow: hidden;
	
	
	.images img {
		
		position: absolute;
		left: -10000em;
		
		&:first-of-type {
			position: absolute;
			left: 50%;
			width: 100%;
			top: 50%;
			transform: translate(-50%,-50%);
			min-height: 400px;
			object-fit: cover;

		}
		
		&.js-randomContent__show {
			position: absolute;
			left: 50%;
			width: 100%;
			top: 50%;
			transform: translate(-50%,-50%);
			min-height: 400px;
			object-fit: cover;
		}
	}
    
    .wrap {
	    height: 300px;
    }
    
    .headline {
	    
	    
		@media screen and ( min-width: 960px )  {
			text-align: left;
			padding-left: 140px;
	    }
	    
		@media screen and ( max-width: 959px )  {
	    	text-align: center;
	    }
	    
	    .deck {
		    margin-top: 20px;
			font: 700 ~"20px/28px" @headline-font;
		    display: inline-block;
		    position: relative;
		    z-index: 1;
		    padding: 0;
		    
		    &:before {
			    content: "";
				position: absolute;
				left: -20px;
				right: -20px;
				top: -5px;
				bottom: -5px;
			    background-color: @cp-color-1;
			    display: block;
				transform: skewX(-8deg);
				z-index: -1;
		    }
	    }
    }
    
    #statements {
	    padding-top: 20px;
	    width: 100%;
	    max-width: 460px;
	    
	    @media screen and ( max-width: 979px )  {
	    	margin-left: auto;
			margin-right: auto;
	    }
	    
    }
}

.headline {
	margin: 0 10px 20px;
	padding: 30px 0;
	width: 66.67%;
	width: ~"calc(100% - 20px)";
	}
	
	
@media screen and (min-width: 960px )  {

	.headline {
		margin: 0 20px 20px;
		width: ~"calc(100% - 40px)";
	}
	
	.headline--statement {    
		
	    .headline {
		    .deck {
			    margin-top: 30px;
				font: 700 ~"26px/34px" @headline-font;
		    }
	    }
	    
	    .wrap {
	    	height: 400px;
    	}
	    
	    #statements {
		    padding-top: 50px;
		    transform: scale(1.5);
	    }
	}	
}

.headline .deck {
	font: 700 ~"20px/24px" @headline-font;
	padding: 0 0 20px;
	text-transform: uppercase;
	}

.headline h1 {
	padding: 0;
	margin: 0;
	}



#headline .wrap.small {
	min-height: 105px;
	
	.headline {
		margin: 0 10px 40px;
		
		@media screen and (min-width: 960px )  {
			margin: 0 20px 40px;
		}
	}

	.headline .deck {
		padding: 0 0 5px;
	}

}




.headlineXL {
	position: relative;
}

.headlineXL h6 {
	position: absolute;
	top: 240px;
}



.headlineL {
	/*
	margin-top: -20px;
	margin-bottom: 20px;
	*/
	margin-bottom: 40px;
	position: relative;
	
	.image {
		/*
		float: right;
		margin: 0;
		*/
		position: absolute;
		right: 0;
		top: 0;
	}
	
	.text {
		position: relative;
		
		margin: 20px 0;
		width: 440px;
		height: 240px;
		padding: 20px;
		background: @bg-color-2;
		
		h1 {
			color: white;
			padding: 0 0 10px;
		}
		
		p {
			font: 400 24px/28px @body-font;
			color: white;
		}
		
	}
	
}



@media screen and ( min-width: 960px )  {



}


@media screen and ( max-width: 959px )  {

	#headline .headline {
		width: auto;
	}

	.headlineXL {
		
		.image {
			width: 100%;
			
			img {
				width: 100%;
			}
			
		}
		
		h6 {
			top: 136px;
		}
		
	}
	
	
}


@media screen and ( max-width: 639px )  {
	
	.headlineXL {
		margin: 0 0 10px !important;
		
		.image {
			margin: 0 0 15px;
		}
		
		h6 {
			position: relative;
			top: auto;
			left: -10px;
		}
		
		h1 {
			padding: 10px 10px 0;
		}
		
	}
	
	.headlineL {
		margin: 0 0 40px !important;
		
		.image {
			position: relative;
			
			img {
				width: 100%;
			}
		}
		
		.text {
			width: auto;
			height: auto;
			margin: 0;
		}
	}
	
	
	.headlineS h1 {
		font: 400 ~"34px/34px" @headline-font;
		margin-top: 10px;
	}
	
	.headlineXS {
		margin: 40px 0 0;
		
		h3 { 
			float: none;
			padding-right: 50px;
			
			&:after {
				right: 0;
			}
		}
	}

}