.carousel {
	margin-bottom: 55px !important;
	}
	
.carousel h2 {
	/*
	border-top: 5px solid @text-color;
	padding: 5px 0;
	font: 400 ~"20px/24px" @headline-font;
	text-transform: uppercase;
	border-bottom: 1px solid #d3d2d3;
	*/
	padding: 0 0 5px;
	font: 700 24px/32px @headline-font;
	}

.carousel-slick {
	padding: 0 0 10px 0;
	margin: 0 -5px;

	.item,
	.slick-slide {
		margin: 0 5px;
		background: white;
		

		img {
			width: 100%;
			width: 100%;
			height: auto;
		}
		
	}
	
	/*
	.slick-slider {
		margin-bottom: 0;
	}

	

	.slick-dots {
		z-index: 1;
		margin: 0 0 0 -25px;
		bottom: -30px;

		li {
			width: 10px;
			height: 10px;

			button {
				background: #dee8eb;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				
				&:hover {
					background: #467a92;
				}
				
				&:before {
					display: none;
				}
			}

			&.slick-active button {
				background: #467a92;
			}
		}
	}
	*/
	
	.slick-slider {
		margin: 0;
	}
	
	.slick-prev, .slick-next {
		height: 40px;
		width: 40px;
		margin: -20px 0 0;
		background:  @cp-color-1;
		position: absolute;
		top: 50%;
		display: block;
		cursor: pointer;
		color: #fff;
		text-align: center;
		line-height: 40px;
		
		i {
			display: block;
			position: relative;
			width: 40px;
			height: 40px;
			color: #fff;
			
			&:before {
				font-size: 22px;
			}
		}

		span {
			text-indent: -9999em;
			display: block;
			visibility: hidden;
		}

		&:hover {
			color: #fff;
			background: @cp-color-2;
			
			i {
				color: #fff;
			}
		}
		
		&:before {
			display: none;
		}
	}

	.slick-prev {
		left: -15px;

		i {
			.icon-arrow_left;
		}
		
	}

	.slick-next {
		right: -15px;

		i {
			.icon-arrow_right;
		}
	}
	
	.slick-dots {
		z-index: 1;
		position: absolute;
		bottom: -20px;
		left: 50%;
		transform: translateX(-50%);
		margin: 0;
	
		li {
			width: 15px;
			height: 15px;
			display: inline-block;
			float: left;
			margin: 0 7px;
	
			button {
				background: #fff;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				padding: 0;
				text-indent: -10000em;
				
				&:hover {
					background: @cp-color-2;
				}
				
				&:before {
					display: none;
				}
			}
	
			&.slick-active button {
				background: @cp-color-1;
			}
		}
	}
}