//
// TODO: Which fonts are still needed?
@font-face {
	font-family: 'Zilla Slab';
	src: url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-regular.woff2') format('woff2'),
		url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-regular.woff') format('woff');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Zilla Slab';
	src: url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-italic.woff2') format('woff2'),
		url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-italic.woff') format('woff');
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'Zilla Slab';
	src: url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-500.woff2') format('woff2'),
		url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-500.woff') format('woff');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Zilla Slab';
	src: url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-500italic.woff2') format('woff2'),
		url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-500italic.woff') format('woff');
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: 'Zilla Slab';
	src: url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-700.woff2') format('woff2'),
		url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-700.woff') format('woff');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Zilla Slab';
	src: url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-700italic.woff2') format('woff2'),
		url('../../statics/fonts/ZillaSlab/zilla-slab-v5-latin-700italic.woff') format('woff');
	font-style: italic;
	font-weight: 700;
}
