.matomo-opt-out__form {
	position: relative;
}

.matomo-opt-out {
	
	.matomo-opt-out__label {
		padding: 0 0 0 33px;
		font: 400 16px/22px @body-font;
		margin: 0 20px 0 0 !important;
		display: inline-block;
		position: relative;
		
		a {
			border-bottom: 1px dotted @cp-color-2;
			
			&:hover,&:active,&:focus {
				color: @text-color;
				border-bottom: 1px dotted #a1a1a1;
			}
		}
		
		&:before {
			width: 22px;
			height: 22px;
			background:#f6f6f6;
			border: 1px solid #d3d2d3;
			content: "";
			position: absolute;
			left: 0;
			top: -1px;
			border-radius: 0;
		}
			
		&:hover{
			
			&:before {
				background-color: #f9f9f9;
				border: 1px solid #a1a1a1 !important;
			}
		
		}		
	}
	
	#matomo-opt-out__toggle {
		margin: 0 !important;
	    position: absolute;
	    left: -10000em;
	    width: auto !important;
	    
		&:checked + .matomo-opt-out__label:before {
			border: 1px solid @cp-color-1;
			background: @cp-color-1 url("@{path}/checkbox.png") center center no-repeat;
		}
		
		&:hover:checked + .matomo-opt-out__label:before {
			background-color: @text-color;	
			border: 1px solid @text-color !important;
		}
	}
}