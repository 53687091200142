a.intern,
a.extern,
a.mail,
a.download {
	padding: 2px 0px 7px 25px;
	display: inline-block;
	position: relative;
	font: 400 14px/20px @body-font;
	color: @text-color;
	border: 0 !important;
	text-decoration: none !important;
	
	.icon-arrow_right;
	
	&:before {
		color: @cp-color-2;
		position: absolute;
		left: 0;
		font-size: 14px;
	}
	
	&:hover {
		color: @cp-color-2;
	}
	
	&:last-child {
		padding-bottom: 0;
	}
	
}


a.intern {
	&:before { font-size: 14px; left: 6px; }
}

a.extern {
	.icon-extern;
	&:before { font-size: 14px; left: 2px; }
}

a.mail {
	.icon-mail;
	&:before { font-size: 13px; }
}

a.download {
	.icon-document;
	&:before { font-size: 18px; left: 2px; }
}
/*
a.extern:before {
	background: url("@{path}/icon_extern_blue.svg") left 6px no-repeat;
	background-size: 9px auto;
	}
a.extern:hover:before {
	background: url("@{path}/icon_extern_black.svg") left 6px no-repeat;
	background-size: 9px auto;
	}
	
	
a.download:before {
	background: url("@{path}/icon_dokumente_blue.svg") left 3px no-repeat;
	background-size: 12px auto;
	}
a.download:hover:before {
	background: url("@{path}/icon_dokumente_black.svg") left 3px no-repeat;
	background-size: 12px auto;
	}


	
a.mail:before {
	background: url("@{path}/icon_mail_blue.svg") left 6px no-repeat;
	background-size: 14px auto;
	}
a.mail:hover:before {
	background: url("@{path}/icon_mail_black.svg") left 6px no-repeat;
	background-size: 14px auto;
	}
	
*/
	

.links {
	background: white;
	width: ~"calc(100% - 20px)" !important;
	padding: 20px 20px 30px;
}

.links h2 {
	// border-top: 5px solid @text-color;
	padding: 0 0 20px;
	font: 700 24px/32px @headline-font;
	// text-transform: uppercase;
	}

.links p {
	/* font: 400 16px/22px @headline-font; */
	font: 400 15px/22px @body-font;
	padding: 0 0 20px;
	}
	
.links h2 + p {
	margin-top: -15px;
	}
	
.links ul {
	list-style: none;
	margin: 0;
	border-top: 1px solid #d3d2d3;
	}

.links li {
	list-style: none;
	margin: 0;
	border-bottom: 1px solid #d3d2d3;
	padding: 15px 0;
	}

.links a {
	color: @text-color;
	font: 700 18px/24px @headline-font;
	padding-left: 55px;
	
	&:before {
		color: @cp-color-2;
		left: 34px;
	}
	
	&.extern:before {
		left: 30px;
	}
	
	&.mail:before {
		left: 28px;
	}
	
	&.download:before {
		left: 30px;
	}
}
.links a:before {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ebbd00;
    text-align: center;
    position: absolute;
    left: 0 !important;
	top: -7px;
}
.links a:hover {
	color: @cp-color-2;
}		
.links a:hover:before {
	background: @cp-color-2;
}
.links a .desc {
	font: 400 15px/22px @body-font;
	color: @cp-color-gray;
	display: block;
	}
.links a .meta {
	font: 400 15px/22px @body-font;
	color: @cp-color-gray;
	display: block;
	}
	
    

/*
@media screen and ( min-width: 960px )  {

	.bloc-320 {
	
		.links {
			width: 300px;
			background: white;
			
			h2 {
				border: 0;
				padding: 10px 15px;
			}
			
			p {
				padding: 0 15px 10px;
			}
			
			a {
				padding-left: 45px;
				
				&:before {
					left: 21px;
				}
	
				&.extern:before {
					left: 17px;
				}
				
				&.mail:before {
					left: 15px;
				}
				
				&.download:before {
					left: 17px;
				}
			}
			
		}
	
	}
	
}

*/




.bloc-320 {
	
	/*
	.links {
		width: 300px;
	}
	
	.links h2 {
		text-align: right;
		text-transform: uppercase;
		color: @cp-color-1;
		font: 400 14px/30px @headline-font;
		padding: 0 50px 0 0;
		position: relative;
		border: 0;
		
		&:after {
			display: block;
			background: @cp-color-1;
			height: 10px;
			width: 40px;
			position: absolute;
			right: 0;
			top: 9px;
			content: "";
		}
		
	}
	
	.links {
		background: @bg-color-3;
		width: 300px;
		}
	
	.links h2 {
		border-top: 1px solid #dceef1;
		padding: 10px 15px;
		font: 400 16px/22px @body-font;
		text-transform: uppercase;
		}
	
	.links ul {
		}
	
	.links li {
		border-top: 1px solid #dceef1;
		padding: 0 15px;
		background: none;
		}
	
	a.intern,
	a.extern,
	a.mail,
	a.download {
		padding: 10px 10px 10px 20px;
		font: 700 14px/20px @headline-font;
		}
		
	a.intern i,
	a.extern i,
	a.mail i {
		left: 0;
		top: 12px;
		}
		
	a.download i {
		background-position: left top;
		}
	
	a.download:hover i {
		background-position: left -300px;
		}
	
	.links a .desc {
		font: 400 12px/18px @body-font;
		color: #919193;
		}
	
	*/
		
}

@media screen and ( max-width: 959px )  { 

	.bloc-320 .links, 
	.bloc-480 .links {
		float: none;
		// width: auto;
	}
}



@media screen and ( min-width: 960px )  {
	
	.links {
    	width: ~"calc(100% - 40px)" !important;
	}
	
    .links li {
		padding: 20px 0;
    }
}