#siteoverview ul {
	margin: 0 10px;
	list-style: none;
	}
	
#siteoverview li {
	list-style: none;
	margin: 0 0 40px;
	}
	
#siteoverview li a,
#siteoverview li span {
	display: block;
	font: 400 ~"20px/30px" @headline-font;
	color: @cp-color-1;
	padding: 0 0 10px;
	}
	
#siteoverview li a:hover {
	color: black;
	}

#siteoverview li ul {
	display: block;
	width: auto;
	margin: 0;
	}

#siteoverview li ul li {
	display: block;
	margin: 0;
	float: none;
	}

#siteoverview li ul li a,
#siteoverview li ul li span {
	display: block;
	font: 400 ~"14px/22px" @body-font;
	color: @text-color;
	margin: 0;
	padding: 0 20px 0 30px;
	background: transparent;
	}

#siteoverview li ul li ul {
	padding: 5px 0 5px;
	}
	
#siteoverview li ul li ul li a,
#siteoverview li ul li ul li span {
	padding: 0 20px 0 60px;
	font: 400 ~"13px/22px" @body-font;
	}