.steps {
	background: @text-color;
	margin: 0 10px 40px;
	text-align: left;
	
	ul {
		display: table;
		width: 100%;
		height: 60px;
		margin: 0;
		overflow: hidden;
	}
}

@media screen and (min-width: 960px )  {
	.steps {
		margin: 0 20px 40px;
	}
}
.steps li {
	color: white;
	font: 700 ~"18px/60px" @headline-font;
	text-transform: uppercase;
	position: relative;
	display: table-cell;
	width: 33%;
	padding: 0 40px;
	list-style: none;
	margin: 0;
	// background: url("@{path}/step.png") left top no-repeat;

	&:before {
		left: 0%;
		top: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-top: -60px;
		border: solid transparent;
		border: solid rgba(0, 0, 0, 0);
		border-width: 60px 0px 60px 20px;
		border-color: transparent transparent transparent @bg-color;
		border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) @bg-color;
		-moz-transform: scale(.9999);
	}

	&:after {
		left: 100%;
		top: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-top: -30px;
		border: solid transparent;
		border: solid rgba(0, 0, 0, 0);
		border-width: 30px 0 30px 10px;
		border-color: transparent transparent transparent @text-color;
		border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) @text-color;
		-moz-transform: scale(.9999);
		z-index: 2;
	}
}
	
.steps li:first-child {
	background: transparent;
	}

.steps li.done,	
.steps li.active {
	background: @cp-color-1;
	color: @text-color !important;
	}
	
.steps li.active:first-child {
	background: @cp-color-1;
	}

.steps li.done {
	background: @cp-color-1 url("@{path}/icon_process_ok.svg") 95% 15px no-repeat;
}

.steps li.done,	
.steps li.active {

	&:after {
		border-color: transparent transparent transparent @cp-color-1;
		border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) @cp-color-1;
	}
}


.steps li:first-child {
	padding: 0 20px;

	&:before {
		display: none;
	}
}

@media screen and ( max-width: 639px )  {

	.steps {

		ul {
			display: block;
			height: auto;
			position: relative;
			background: #fff;
			overflow: visible;

			li {
				display: block;
				width: auto;
				margin-bottom: 2px;
				background: #F1F5F5;
				padding-left: 20px;
				

				&:before {
					left: 50%;
					top: 100%;
					content: " ";
					height: 0px;
					width: 0px;
					position: absolute;
					pointer-events: none;
					margin-left: -10px;
					border-style: solid;
					-moz-border-top-colors: none;
					-moz-border-right-colors: none;
					-moz-border-bottom-colors: none;
					-moz-border-left-colors: none;
					border-image: none;
					border-width: 10px 20px 0px 20px;
					border-color: #fff transparent transparent transparent;
					border-color: #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					transform: scale(0.9999);
					z-index: 2;
				}

				&:after {
					left: 50%;
					top: 150%;
					content: " ";
					height: 0px;
					width: 0px;
					position: absolute;
					pointer-events: none;
					margin-left: -10px;
					border-style: solid;
					-moz-border-top-colors: none;
					-moz-border-right-colors: none;
					-moz-border-bottom-colors: none;
					-moz-border-left-colors: none;
					border-image: none;
					border-width: 10px 20px 0px 20px;
					border-color: #F1F5F5 transparent transparent transparent;
					border-color: #F1F5F5 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					transform: scale(0.9999);
					z-index: 3;
				}

				&.done,	
				&.active {

					&:after {
						border-color: @cp-color-1 transparent transparent transparent ;
						border-color: @cp-color-1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					}
				}
			}
		}
		
	}
}