// PATHS
@path: ~'../../images';

// GRID
@page-width: 960px;
// @page-width: 1240px;

// MEDIA QUERIES
@mediaquery-tablet: 759px;

// COLORS
@cp-color-back: #000;
@cp-color-white: #fff;

@cp-color-1: #ebbd00;
@cp-color-2: #d32e17;
@cp-color-3: #1c909f;
@cp-color-4: #eb8e00;
@cp-color-5: #265268;

@cp-color-gray-1: #f4f3f1;
@cp-color-gray-2: #eeeeee;
@cp-color-gray-3: #d9dbdd;
@cp-color-gray-4: #999;
@cp-color-gray-5: #1a171b;

@theme-color-1: #ebaf00;
@theme-color-2: #ebda00;
@theme-color-3: #ebb200;
@theme-color-4: #ebc600;
@theme-color-5: #eb8e00;

@bg-color-1: @cp-color-gray-1;
@bg-color-2: @cp-color-gray-2; // navi bg
@bg-color-3: @cp-color-gray-2; // aside bg, footer bg
@bg-color-4: @cp-color-5; // highlight bg

@text-color: @cp-color-gray-5;

@link-color: @cp-color-2;
@link-color-hover: @text-color;

// BASE
@body-bg: @bg-color-1;

// FONTS
@body-font: 'Roboto', sans-serif;
@body-font-2: 'Roboto Bold', sans-serif;
@headline-font: 'Zilla Slab', serif;
@mono-font: Consolas, 'Courier New' monospace;

// BASE
@body-font-size: 16px;
@body-font-line-height: 24px;
@body-font-color: @text-color;
@content-margin-bottom: 20px;

// HEADLINES
@headline-font-size: 44px;
@headline-font-color: @text-color;
@headline-font-line-height: 48px;
@headline-font-weight: 400;

// TABLES
@table-head-bg: @cp-color-1;
@table-font-color-head: @cp-color-white;
@table-bg: @cp-color-white;
@table-font-color: @text-color;
@table-bg-hover: @cp-color-white;
@table-font-color-hover: @text-color;
@table-padding: 0 0 5px;
@table-border: 0;

// FORMS
@form-margin-bottom: 15px;
@form-padding: 10px;
@form-font-color: @text-color;
@form-bg: @cp-color-white;
@form-border: @border;
@form-border-hover: @border;

// BUTTONS
@button-bg: @cp-color-1;
@button-bg-hover: @cp-color-2;
@button-border: 0;
@button-font: @body-font;
@button-font-color: @cp-color-white;
@button-padding: 5px 10px;

// BORDERS
@border: 1px solid @cp-color-gray-3;

// REMAP DEPRECATED VARIABLES
@body-font-3: @body-font;
@bg-color: @bg-color-1;
@cp-color-gray: @cp-color-gray-4;
