.publications {
	/*
	margin: 0 10px;
	
	.teaser {
		width: 100%;
		margin: 0 !important;
	}
	*/
	.teaser {
		float: none;
	}
	
	.image {
		
		width: 380px;
		text-align: center;
		
		img {
			box-shadow: 5px -5px 0 0 white;
			margin: 0 auto;
			width: auto;
			border-bottom: 1px solid #8c8c8c;
			max-width: 100%;
			max-height: 180px;
		}
		
	}
	
	.read-now,
	.download-doc {
		font: 400 15px/46px @body-font-2;
		color: @text-color;
		position: relative;
		padding: 0 0 0 60px;
		margin: 0 20px 0 0;
		display: inline-block;
		
		.icon-view;
		
		&:before {
			color: @cp-color-2;
			position: absolute;
			left: 0;
			top: 0;
			background: white;
			height: 46px;
			width: 46px;
			border-radius: 23px;
			text-align: center;
			
			font-size: 14px;
		}
		
		&:hover, &:active, &:focus {
			color: @cp-color-2;
		}
		
	}
	
	
	.download-doc {
		.icon-download;
		
		&:before {
			font-size: 20px;
		}
		
	}
	
	
	
	
	.bgYellow {
		border: 0;
		margin-bottom: 40px !important;
		padding: 30px 30px 40px 0;
		
		h2 {
			font: 700 32px/40px @headline-font;
			padding: 0 0 20px;
		}
		
		h6 {
			font: 400 14px/20px @body-font-2;
		}
		
		p {
			font: 400 16px/24px @body-font;
			padding: 0 0 30px;
		}
		
		.image img {
			box-shadow: 5px -5px 0 0 #f4f3f1;
			border-bottom: 1px solid @text-color;
			background-color: #fff;
		}
		
	}
	
	
}

@media screen and ( max-width: 959px )  {

	.publications {

		.image {
			width: 310px;

			img {
				max-width: 100%;
			}
			
		}

		.read-now {
			display: none;
		}
	}
}

@media screen and ( max-width: 639px )  {

	.publications {

		.image {
			width: 160px;

			img {
				max-width: 100%;
			}
			
		}
	}
}

@media screen and ( max-width: 479px )  {

	.publications {

		.image {
			width: auto;
			float: none;
			margin: 0 0 20px 0;
		}

		.read-now {
			margin-bottom: 10px;
		}
	}
}