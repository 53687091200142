#themeportal {
	margin: 60px 0 0;
	background: @cp-color-1;
	
	.wrap {
		width: ~"calc(100% - 40px)";
		max-width: 1200px;
	}
	
	h2 {
		/*
		text-transform: uppercase;
		font-size: 42px;
		line-height: 42px;
		*/
		margin: -60px 0 0;
		padding: 0;
	}

	.teaser {
		margin: 0;
		width: 20%;
		height: auto;
		padding: 0;
		
		h3 {
			text-transform: uppercase;
			font-size: 18px;
			line-height: 22px;
			position: absolute;
			left: 0;
			top: 0;
			padding: 20px;
		}
		
		.image {
			float: none;
			margin: 0;
			z-index: 2;
			position: relative;
			
			img {
				width: 100%;
				height: auto;
			}
		}
		
		&:hover {
			h3 {
				color: @text-color !important;
			}
		}
		
	}
	
}


.themeteaser {
	height: 260px;
	margin: 15px 10px 80px !important;
	
	.theme {
		margin-left: 0;
		margin-right: 0;
		width: 240px;
		
		h3 {
			text-transform: uppercase;
			font-size: 22px;
			line-height: 24px;
			position: absolute;
			left: 0;
			top: 0;
			padding: 10px 15px;
			color: @text-color !important;
		}
		
		.image {
			margin: -15px;
		}
		
		.read-all {
			position: absolute;
			left: 10px;
			bottom: -25px;
			padding-right: 12px;
			color: @text-color;
			font: 400 ~"14px/20px" @body-font;
			display: inline-block;
			background: url("@{path}/icon_arrow_right_black.svg") right 4px no-repeat;
			background-size: 4px auto;
			
		}
		
		&:hover, &:focus, &:active {
			
			h3 {
				color: @text-color !important;
			}
			
			.read-all {
				background-image: url("@{path}/icon_arrow_right_red.svg");
				color: @cp-color-2;
			}
		}
		
	}
	
	a.teaserM {
		width: 220px;
		height: 290px;
		background: white;
		margin: -15px 10px;
		
		h3 {
			font-size: 16px;
			line-height: 20px;
			padding: 0;
			display: inline;
		}

		p {
			display: none;
		}
			
	}
	
	a {
		
		&:nth-child(2) {
			margin-left: 0;
		}
		
		&:last-of-type {
			margin-right: 0;
		}
	
	}
	
}



/*
	BACKGROUNDS
*/
.themeBg1 { background-color: @theme-color-1; }
.themeBg2 { background-color: @theme-color-2; }
.themeBg3 { background-color: @theme-color-3; }
.themeBg4 { background-color: @theme-color-4; }
.themeBg5 { background-color: @theme-color-5; }


@media screen and ( max-width: 959px )  {

	#themeportal .wrap {
	    width: 640px;
	}
	
	#themeportal .teaser {
		width: 33.33%;
		float: left;
		height: 390px;
	}

	#themeportal .teaser .image {
		height: 100%;
		margin: 0;
	}

	#themeportal .teaser .image img {
		position: absolute;
		bottom: 0px;
	}

	#themeportal .teaser h3 {
		z-index: 2;
	}

	.themeteaser {
		height: auto;
		padding-bottom: 0px;
		margin-bottom: 40px !important;
		//padding-left: 5px;
		//padding-right: 5px;

		a.teaserM {
			width: 33.33%;
			margin: 0;
			float: left;
			box-sizing: border-box;
			border-right: 5px solid #EBAF00;
			border-left: 5px solid #EBAF00;
			padding: 0;

			&:nth-child(2) {
				border-left: 0 none;
			}

			&:last-child {
				border-right: 0 none;
				float: right; // rounding...
			}

			.image {
				width: 100%;
				float: none;
				margin: 0;

				img {
					width: 100%;
					height: auto;
				}
			}

			.text {
				width: 100%;
				float: none;
				margin: 0;
				box-sizing: border-box;
				padding: 10px;
			}
		}

		&.themeBg1 {

			a.teaserM {
				border-color: #EBB200;
			}
		}

		&.themeBg2 {

			a.teaserM {
				border-color: #EBDA00;
			}
		}

		&.themeBg3 {

			a.teaserM {
				border-color: #EBB200;
			}
		}

		&.themeBg4 {

			a.teaserM {
				border-color: #EBC600;
			}
		}

		&.themeBg5 {

			a.teaserM {
				border-color: #EB8E00;
			}
		}

		.theme {
			width: 100%;

			h3 {
				max-width: 400px;
			}

			.image {
				width: 150px;
				margin: 0 auto;
			}

			.read-all {
				bottom: auto;
				right: 10px;
				left: auto;
				top: 10px;
			}
		}
	}
}

@media screen and ( max-width: 639px )  {

	#themeportal .wrap {
	    width: 100%;
	}

	.themeteaser {

		a.teaserM {
			width: 100%;
			border: 0 !important;
			height: auto;
			padding-bottom: 15px;
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;

			}

			h3 {
				display: block;
				margin-bottom: 10px;
			}

			.image {
				width: auto;
				float: left;
				margin-top: 15px;

				img {
					width: auto;
				}
			}

			.text {
				width: auto;
				padding: 15px;

				p {
					display: block;
				}

				.read-more {
					text-indent: 0;
					display: inline;
					padding-right: 10px;
					background-position: right 4px;
				}
			}

		}
	}
}

@media screen and ( max-width: 479px )  {

	#themeportal .teaser {
			width: 50%;
			float: left;
	}

	.themeteaser {
		margin-bottom: 30px !important;
		
		a.teaserM {

			.image {
				display: none;
			}

			.text {
				padding: 15px 10px;
			}
		}

		.theme {
			width: 100%;

			.image {
				margin: 20px 0 -25px -15px;
			}

			.read-all {
				bottom: 0px;
				right: auto;
				right: 15px;
				top: auto;
			}
		}
	}
}
