.embed-responsive {
	position: relative;
display: block;
height: 0;
padding: 0;
    padding-bottom: 0px;
padding-bottom: 0px;
overflow: hidden;
}
	
.embed-responsive-4by3 {
 padding-bottom: 75%;
}
.embed-responsive-16by9 {
 padding-bottom: 56.25%;
}
.embed-responsive .embed-responsive-item, 
.embed-responsive embed, 
.embed-responsive iframe, 
.embed-responsive object, 
.embed-responsive video {
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 width: 100%;
 height: 100%;
 border: 0;
}

.video__embed {
	width: 100%;
	background-color: #d8d8d8;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;	
	position: relative;
}

.video__embed:before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 80px;
	height: 80px;
	background: #ebbd00;
	border: 4px solid #ebbd00;
	border-radius: 50%;
	/* box-shadow: -2px 2px 4px rgba(0,0,0,.1); */
}

.video__embed:after {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	/*
	width: 30px;
	height: 20px;
	box-shadow: -2px 2px 4px rgba(0,0,0,.1);
	*/	
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent; 
	border-left: 30px solid black;
	margin-left: 5px;
}

.video__embed iframe,
.video__embed video {
	z-index: 10;
}


.video__loadinfo {
	/* padding-top: 40px; padding-left: 90px; width: 250px; position: absolute; z-index: 100; display: none; */
	background: white;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	padding: 15px;
	max-width: 480px;
	text-align: center;
	opacity: 0;
	z-index: 1;
	transition: all ease-in-out 600ms ease-in-out;
}

.video__loadinfo p {
	margin: 0;
	font: 400 13px/20px "Roboto Regular", Arial;
}

.video__link:hover {
	color: black;
	text-decoration: underline;
	
}

.video__media:hover .video__loadinfo {
	opacity: 1;
}

/*
.video__media:hover .video__embed:before {
	background: black;
	border: 4px solid black;
}

.video__media:hover .video__embed:after {
	border-left: 30px solid white;
}
*/


@media screen and (max-width: 480px) {
	
	.video__embed {
		margin: 0 0 155px;
		overflow: visible;
	}
	
	.video__loadinfo {
		left: 0;
		top: 100%;
		transform: none;
		opacity: 1;
		height: 154px;
	}
}




#content {

	.video,
	.audio {
		/*background: #fff; */
		}
	.video h2,
	.audio h2,
	.video h3,
	.audio h3 {
	/*
		border-top: 10px solid @bg-color-3;
		padding: 15px 0;
	*/
		margin: 0;
		}
	
	.video .player,
	.audio .player {
		height: 100%;
		width: 100%;
		background: #fff;
		display: block;
		}
	
}


@media screen and ( max-width: 639px )  {
	
	#content {
		
		.video h2,
		.audio h2 {
			margin: 0 10px;
		}
		
	}

}


.mejs-offscreen {
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px,1px,1px,1px);
	clip-path: polygon(0px 0,0 0,0 0,0 0);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}


.mejs-dgb .mejs-controls {
	background: #000;
	height: 25px;
	}

.mejs-dgb .mejs-button,
.mejs-dgb .mejs-time {
	background: #000;
	}

.mejs-dgb .mejs-overlay-button {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: 100px;
	margin: -50px 0 0 -50px;
	background: url("@{path}/videoskin/display/play.png") center center no-repeat;
	}

.mejs-dgb .mejs-overlay:hover .mejs-overlay-button {
	background: url("@{path}/videoskin/display/play.png") center center no-repeat;
	}

.mejs-dgb .mejs-overlay .mejs-overlay-button:hover {
	background: url("@{path}/videoskin/display/play_mo.png") center center no-repeat;
	}



.mejs-container .mejs-controls div {
	height: 25px;
	width: 25px;
	line-height: 25px;
	}

.mejs-dgb .mejs-controls .mejs-button button {
	height: 25px;
	width: 25px;
	line-height: 25px;
	margin: 0;
	padding: 0;
	}



.mejs-dgb .mejs-controls .mejs-play button {
	background: url("@{path}/videoskin/controlbar/play.png") center center no-repeat;
	}
.mejs-dgb .mejs-controls .mejs-play:hover button {
	background: url("@{path}/videoskin/controlbar/play_mo.png") center center no-repeat;
	}
	
.mejs-dgb .mejs-controls .mejs-pause button {
	background: url("@{path}/videoskin/controlbar/pause.png") center center no-repeat;
	}
.mejs-dgb .mejs-controls .mejs-pause:hover button { 
	background: url("@{path}/videoskin/controlbar/pause_mo.png") center center no-repeat;
	}



.mejs-dgb .mejs-controls .mejs-mute button {
	background: url("@{path}/videoskin/controlbar/sound.png") center center no-repeat;
	}
.mejs-dgb .mejs-controls .mejs-mute:hover button {
	background: url("@{path}/videoskin/controlbar/sound_mo.png") center center no-repeat;
	}
	
.mejs-dgb .mejs-controls .mejs-unmute button {
	background: url("@{path}/videoskin/controlbar/soundOff.png") center center no-repeat;
	}
.mejs-dgb .mejs-controls .mejs-unmute:hover button { 
	background: url("@{path}/videoskin/controlbar/soundOff_mo.png") center center no-repeat;
	}
	

.mejs-dgb .mejs-controls .mejs-fullscreen-button button {
	background: url("@{path}/videoskin/controlbar/fullscreen.png") center center no-repeat;
	}
.mejs-dgb .mejs-controls .mejs-fullscreen-button:hover button {
	background: url("@{path}/videoskin/controlbar/fullscreen_mo.png") center center no-repeat;
	}

.mejs-dgb .mejs-controls .mejs-captions-button {
	display: none;
}


.mejs-container .mejs-controls .mejs-time {
	height: 25px;
	width: 45px;
	text-align: center;
	line-height: 25px;
	padding: 0;
}

.mejs-container .mejs-controls .mejs-time span {
	line-height: 14px;
	margin: 6px 0 5px;
	font-weight: 700;
	width: 45px;
	text-align: center;
	display: block;
}




.mejs-dgb .mejs-controls div.mejs-time-rail {
	padding-top: 2px;
	height: 23px;
	line-height: 23px;
}

.mejs-dgb .mejs-controls .mejs-time-rail span {
	border-radius: 0;
}

.mejs-dgb .mejs-controls .mejs-time-rail .mejs-time-total {
	margin: 6px 0;
	height: 8px;
	background: url("@{path}/videoskin/controlbar/time-slider-rail.png") left top repeat;
}

.mejs-dgb .mejs-controls .mejs-time-rail .mejs-time-buffering {
}

.mejs-dgb .mejs-controls .mejs-time-rail .mejs-time-loaded {
	background: url("@{path}/videoskin/controlbar/time-slider-buffer.png") left top repeat;
}

.mejs-dgb .mejs-controls .mejs-time-rail .mejs-time-current {
	background: url("@{path}/videoskin/controlbar/time-slider-progress.png") left top repeat;
}




.mejs-controls div.mejs-horizontal-volume-slider {
	height: 25px;
	width: 56px;
	margin: 0 0 0 -1px;
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
	width: 40px;
	border-radius: 0;
	top: 0;
	height: 25px;
	margin: 0 0 0 8px;
	background: url("@{path}/videoskin/controlbar/volume-slider-rail.png") -3px 2px repeat;
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	border-radius: 0;
	top: 0;
	height: 25px;
	margin: 0 0 0 8px;
	background: url("@{path}/videoskin/controlbar/volume-slider-progress.png") -3px 2px repeat;
}