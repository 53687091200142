/* --------------------------------

Tabs

-------------------------------- */

.tabs__shadow {
	box-shadow: inset 0 -11px 11px -11px rgba(0, 0, 0, 0.13);
}

.tabs__headline {
	width: 100%;
	display: block;
	text-align: center;
}

.tabs__list {
	width: 100%;
	padding: 0;
	list-style: none;
	display: none;
	flex-direction: column;
}

.tabs__buttons {
	display: none;

	@media screen and ( min-width: 959px )  {
		display: block;
	}
}

.tabs__button {
	margin-left: 1rem;

	/*
	.teaser-set--list & {
		margin: 0;
		width: 100%;
		
		@media screen and ( min-width: 719px )  {
			width: auto;
		}
		
		@media screen and ( min-width: 959px )  {
			display: none;
		}
	}
	*/
}

.tabs__list-item {
	margin-bottom: 0.5rem;
}

.tabs__panel {
	margin: 0 10px 40px;
	background-color: white;
	padding: 20px 15px 30px;
	
	@media screen and ( min-width: 960px )  {
		margin: 0 20px 80px;
		padding: 40px 20px;
	}
	// border-top: 7px solid white;
	
	.teaser {
		float: left;
		
		.text {
			margin: 0 -20px -20px;

			
			h6 {
				// font-family: @body-font-2;
				font: 700 ~"15px/20px" @body-font-2;
				color: @cp-color-2;
				padding-bottom: 5px;
			}
			
			p {
				display: none;
			}
		}
	}
}

#latestNews .teaser {
	margin-bottom: 0;
}

#upcomingEvents .teaser {
	margin-bottom: 0;
	// padding-top: 0;
}

.tabs__panel + .tabs__panel {
	// padding-top: 4rem;
	// padding-top: 0;
}

.read-all {
	font: 700 ~"16px/20px" @body-font-2;
	padding: 50px 10px 0;
	text-align: right;
	
	@media screen and ( min-width: 960px )  {
		padding: 0 20px;
	}
	
	a {
		.icon-arrow_right;
		color: @text-color;
		font: 700 18px/24px @headline-font;
		padding-right: 55px;
		position: relative;
		position: relative;
		z-index: 1;
		
		&:before {
			position: absolute;
			font-size: 14px;
			left: auto;
			color: #fff;
			width: 40px;
			height: 40px;
			line-height: 40px;
			background: #ebbd00;
			text-align: center;
			right: 0;
			top: -9px;
		}
	}
	a:hover {
		color: @cp-color-2;
	}		
	a:hover:before {
		background: @cp-color-2;
	}
}

@media screen and ( min-width: 959px )  {
	
	

	.tabs__panel--hidden {
		visibility: hidden;
		height: 0;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}

	.tabs__headline {
		display: none;
	}

	.tabs__title {
		display: none;
	}

	.tabs__list {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		margin: 0 20px;
	}

	.tabs__list-item {
		// flex: 1 1 calc(50% - 6rem);
		// flex: 0 0 auto;
		margin: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	.tabs__tab {
		display: block;
		white-space: nowrap;
		text-align: center;
		text-decoration: none;
		color: #000;
		position: relative;
		margin-right: 2rem;
		padding: 20px 0 20px;
		font: 700 28px/36px 'Zilla Slab', serif;
		
		// font: 700 ~"20px/24px" @headline-font;
		text-transform: uppercase;

		&:after {
			// background-color: @text-color;
			// transition: $transition-base;
			top: 0;
			content: '';
			height: 7px;
			left: 0;
			right: 0;
			position: absolute;
			z-index: 1;
			opacity: 1;
		}

		&:hover {
			// background-color: $color-blue;
			color: @cp-color-2;
			
			
			&:after {
				//background-color: @cp-color-2;
			}
		}

		.icon {
			display: none;
		}
	}

	.tabs__list-item--selected {
		
		.tabs__tab {
			// color: $color-brand-2;
			position: relative;
			// transition: $transition-base;

			&:after {
				background-color: @cp-color-2;
			}
		}
	}
}
/*
@include media-breakpoint-up(lg) {
	.tabs__list {
		width: 80%;
	}
	.tabs__list-item {
		flex: 1 1 calc(50% - 4rem);
	}
}
*/

.tabs__panel .slideshow__dots {
	display: block;
	margin-top: .5rem;
	margin-bottom: 1rem;
}
	
.tabs__panel .slideshow__status,
.tabs__panel .slideshow__arrow {
		display: none !important;
	}

@media screen and ( min-width: 1239px )  {
	
	.tabs__panel .slideshow__dots {
		display: none;
	}	
}
