#meta li:last-child a {
    padding-right: 10px !important;
}

#search {

	a {
		float: left;
	}

	form {
		min-width: 20px;
		float: left;
		position: relative;
		display: none;

		input {
			border: none;
			border-bottom: 1px solid #dbe5ea;
			background: #f2f6f6;
			height: 20px;
			padding: 0px 10px;
			width: 170px;
			font: 400 12px/20px "Roboto Regular",Arial;
			margin-top: 4px;
			outline: none;

			&:hover, &:focus {
				border: none;
				border-bottom: 1px solid #dbe5ea;
				background: #f2f6f6;
			}

			&:focus {
				font-family: @body-font-2;
			}
		}

		button {
			position: absolute;
			//width: 40px;
			height: 40px;
			top: 0;
			right: 0;
			margin: 0 -10px 0 0;
			padding: 0 10px;
			//background: url("@{path}/icon_lupe.svg") 1px 10px no-repeat;
			//background-size: 16px;
			background: transparent;
			text-shadow: none;
			
			//.icon_lupe;
			&:before {
				color: #467A92;
				font-size: 15px;
				line-height: 1;
			}

			span {
				text-indent: -9999em;
				display: inline-block;
			}
		}

		&.open {
			display: block;
			
			button {
				//background-image: url("@{path}/icon_lupe.svg");
				right: 10px;
			}
			
		}

		&.closed {
			input {
				display: none;
			}
		}
	}

	&:hover {
		
		a, button {
			color: @cp-color-1 !important;
		}

		button {
			//background-image: url("@{path}/icon_lupe_hover.svg");
		}
	}
}

.tx-indexedsearch {
	
	.searchbox {
		margin: 0 10px 40px;
		
		@media screen and (min-width: 960px) {
			margin: 0 20px 40px;
		}
	}
	
	form legend {
		display: none;
	}

	.tx-indexedsearch-form {
		width: 75%;
		float: left;
	}

	.tx-indexedsearch-search-submit {
		width: 20%;
		float: right;
	}

	.tx-indexedsearch-search-submit input[type="submit"] {
		.buttonL;
		background: #D32E17 !important;
		font: 400 18px/26px "Bree Serif Regular",Arial;
		margin-top: 28px;

		&:hover {
			background: #000 !important;
		}
	}

	.listL {
		float: none;

		h6 {
			margin: 0;
			}
		h2 {
			margin: 0;
		}
		
		.textBox {
			//margin-left: -10px;
		}
	}
}

.tx-indexedsearch-res:before {
  content: "";
  display: table;
}
.tx-indexedsearch-res:after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}



.tx-indexedsearch > p {
	padding: 0 10px 20px;
}

.tx-indexedsearch-res .textBox > p {
	padding: 0 0 10px;
}
.tx-indexedsearch-res .textBox > h2,
.tx-indexedsearch-res .textBox > h6 {
	margin: 0;
}

@media screen and ( max-width: 479px )  {

	.tx-indexedsearch {

		.tx-indexedsearch-form {
			width: auto;
			float: none;
		}

		.tx-indexedsearch-search-submit {
			width: auto;
			float: none;
		}

		.tx-indexedsearch-search-submit input[type="submit"] {
			margin-top: 0;

		}
	}
}