//
// Count-up figures on the start page
//

.teaser.teaserFigures {
	// TODO: fix overly specific code and get rid
	// of the wrapping and combined selector

	.figures {
		display: flex;
		flex-wrap: wrap;
		padding: 60px 0 65px;
		background-image: url('/frontend/assets/images/karte.svg');
		background-size: 60% auto;
		background-position: center;
		background-repeat: no-repeat;
	}

	.figures__item {
		display: flex;
		flex-direction: column;
		flex: 0 0 100%;
		margin: 0;
		font: 700 80px/70px @headline-font;
		font-feature-settings: 'tnum', 'lnum';

		&:nth-of-type(1) {
			padding-right: 80px;
		}

		&:nth-of-type(2) {
			flex: 0 0 70%;
			padding: 30px 0 0;
		}

		&:nth-of-type(3) {
			flex: 0 0 30%;
			padding: 0 0 30px;
		}
	}

	.figures__label {
		padding-top: 0.25em;
		font: 400 22px/24px @body-font;
	}
}
