.accordeon {
	// border-bottom: 1px solid #d3d2d3;
	width: ~"calc(100% - 20px)";
	background: white;
	border: 0;
	padding: 20px 20px 30px;
}


.accordeon h2 {
	// border-top: 5px solid @text-color;
	padding: 0 0 20px;
	font: 700 24px/32px @headline-font;
	// text-transform: uppercase;
	}
	
.accordeon p {
	/* font: 400 16px/22px @headline-font; */
	font: 400 15px/22px @body-font;
	padding: 0 0 20px;
	}

.accordeon h2 + p {
	margin-top: -15px;
	}

.accordeon .item {
	margin: 0;
	padding: 0;
	border-top: 1px solid #d3d2d3;
	}
	
.accordeon .item:last-of-type {
	border-bottom: 1px solid #d3d2d3;
	}
	
.accordeon .text {
	padding: 0;
	}

.accordeon .item h3 {
	font: 700 18px/24px @headline-font;
	display: block;
	padding: 20px 55px 20px 0;
	cursor: pointer;
	margin: 0;
	position: relative;
	color: @text-color;
	}

	
@media screen and (min-width: 960px )  {

	.accordeon .item h3:hover {
		color: @cp-color-2;
	}
}

.accordeon .item {

	h3 {
		.icon-arrow_down;
		
		&:before {
			color: @cp-color-2;
			position: absolute;
			right: 0;
			font-size: 5px;
			color: #fff;
		    width: 40px;
		    height: 40px;
		    line-height: 40px;
		    background: #ebbd00;
		    text-align: center;
		    top: 12px;
		}

		@media screen and (min-width: 960px )  {

			&:hover:before {
				background: @cp-color-2;
			}
		}
	}

	&.open h3 {
		.icon-arrow_up;
	}
	
	p, li {
		font: 400 14px/20px @body-font;
	}
	
}
	
	
.accordeon .item .inner {
	display: none;
	}
	
.accordeon .open .inner {
	display: block;
	padding: 10px 0 0;
	}

.accordeon .media,
.accordeon .mediaLeft,
.accordeon .mediaRight {
	margin: 0;
	}
	

@media screen and ( min-width: 960px )  {

	.bloc-320 {
	
		.accordeon {
			
			.item {
				
				/*
				h3 {
					padding: 15px 35px 15px 0;
					font: 700 20px/28px @headline-font;
					
					&:before {
						right: 0;	
					}
				}
				*/
				
				p {
					padding: 0 0 20px;
				}
			}
			
			
		}
		
		.accordeon .media, .accordeon .mediaLeft, .accordeon .mediaRight {
			float: none;
			padding: 0;
			margin: 0 -15px 10px;
			
			.credits {
				padding: 2px 15px;
			}
			
			.caption {
				color: #aaa;
				padding: 5px 15px;
			}
		}
	
	}
}


@media screen and ( min-width: 960px )  {
	
	
	.accordeon {
    	width: ~"calc(100% - 40px)" !important;
	}
}