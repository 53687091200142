.lfv-map {
	max-width: 600px;
    margin: 0 auto;
	
	* {
		box-sizing: border-box;
	}
}


.lfv-map__svg-container {
	display: inline-block;
	height: auto;
	position: relative;
	user-select: none;
	width: ~"calc(100% - 40px)";
	padding-top: 135.24451946%;
	margin-left: 20px;
}


.lfv-map__svg {
	cursor: pointer;
	width: 100%;
	height: auto;
	left: 0;
	position: absolute;
	top: 0;
	overflow: visible !important;


	[class$="-map"] {
		fill: #EB8E00;
		fill: red;
	}


	[class$="-map"]:hover {
		fill: #EB8E00;
	}


	text {
		display: none;
	    fill: @text-color;
	    z-index: 2;
	    font: 400 16px/24px "Bree Serif Regular", Arial;
	}
}


.lfv-map-links {

	li {
		padding-right: 10px;
	}
}
