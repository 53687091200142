.nav-main__list-level-1 {
	list-style: none;
	overflow: visible;
	position: static;
	display: flex;
	margin: 0 -1rem 0 0;
	padding: 0;
}

.nav-main__list-level-1::after {
	background-color: #fff;
	content: '';
	display: block;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 100vw;
	z-index: -1;
	top: -180px;
	bottom: 0;
}

.nav-main__item-level-1 {
	margin: 0;
}

.nav-main__link-wrap {
	position: relative;
	display: flex;
}

.nav-main__link-level-1 {
	font: 700 ~'20px/24px' @headline-font;
	color: @text-color;
	display: flex;
	overflow: hidden;
	padding: 0.75rem 1rem;
	text-transform: uppercase;

	&:hover,
	&:focus,
	&:active,
	&.is-current {
		color: @cp-color-2;
	}
}

.nav-main__sub-toggle {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	position: absolute;
	width: 40px;
	height: 40px;
	bottom: -25px;
	left: 50%;
	padding: 5px;
	transform: translateX(-50%) rotate(-180deg);
	color: @cp-color-2;
	overflow: hidden;
	opacity: 0;
	transition: all 0.2s ease;
}

.nav-main__sub-toggle:hover,
.nav-main__sub-toggle:active {
	background-color: transparent;
}

.nav-main__sub-toggle:active:focus,
.nav-main__sub-toggle:focus {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(25%, -50%);
}

.nav-main__sub-toggle .icon {
	display: block;
	transition: all 0.2s ease;
}

.is-open .nav-main__sub-toggle {
	opacity: 1;
}

.nav-main__flyout {
	position: absolute;
	top: 100%;
	transition: all 0.3s ease;
	z-index: -10;
	will-change: transform;
	transform: translateY(-100%);
	opacity: 0;
	visibility: hidden;
	box-shadow: 0 18px 18px 0 rgba(0, 0, 0, 0.2);
	width: 245px;
	hyphens: auto;
	word-break: break-word;
	margin: -1rem -0.5rem 0;
}

.is-open .nav-main__flyout {
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
}

.nav-main__item-level-1:last-child .nav-main__flyout {
	margin: -1rem 0 0 -4.5rem;
}

.nav-main__list-level-2 {
	width: 100%;
	background-color: #fff;
	list-style: none;
	margin: 0;
	padding: 1rem 0 0;
}

.nav-main__item-level-2 {
	position: relative;
	margin: 0;
	padding: 0;

	&:last-child {
		.nav-main__link-level-2 {
			padding-bottom: 1.125rem;
		}
	}
}

.nav-main__link-level-2 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font: 400 ~'16px/20px' @body-font-2;
	color: @text-color;
	display: block;
	height: 100%;
	transition: all 0.2s ease;
	position: relative;
	padding: 0.625rem 1.5rem;

	&:hover,
	&:focus,
	&:active {
		color: @cp-color-2;
		background-color: #f4f3f2;
	}

	&.is-current {
		color: @cp-color-2;
	}
}

.header__bottom--exhibition {
	margin-bottom: 0;
}

.nav-main--exhibition {
	.nav-main__item-level-1 {
		border-right: 1px solid @cp-color-1;

		.nav-main__link-wrap {
			height: 100%;
			justify-content: center;
		}

		&:last-of-type {
			border: 0;
		}
	}

	.nav-main__link-level-1 {
		font: 700 ~'16px/20px' @headline-font;
		align-items: center;
		height: 'calc( 100% - 2rem)';
		justify-content: center;
		text-align: center;
		padding: 0.5rem 0.75rem;
	}
}

@media (min-width: 1200px) {
	.nav-main--exhibition .nav-main__link-level-1 {
		font: 700 ~'18px/22px' @headline-font;
		padding: 1rem 1.25rem;
	}
}
