.buttonCTA {
	margin: 0 10px 40px;
}

@media screen and (min-width: 960px )  {
	.buttonCTA {
		margin: 0 20px 40px;
	}
}
#seminarForm {
	
	.formLeft {
		width: 560px;
		padding: 0 40px 20px 0;
		float: left;
	}
	
	.formRight {
		float: left;
		width: 280px;
		padding: 0 0 20px;
	
		textarea {
			width: 100%;
			height: 400px;
		}
	}

	.countryUnion .checkbox {
		padding-top: 27px !important;
	}
	
	/*
	.buttonCTA {
		margin: 20px 0;
		padding: 0;
		width: 100%;
		
		span {
			margin: 0 20px;
		}
	}
	*/
	
	.tooltip {
		span {
			left: auto;
			right: -220px;	
		}
	}
	
}

#seminarCheck {
	
	.submit {
		margin-top: -20px;
	}
	
}


.seminar {
	border-bottom: 1px solid #d3d2d3;

	h2 {
		padding: 15px 0;
		margin: 0;
	}
	
	.timeslot {
		padding: 15px 0;
		border-top: 1px solid #d3d2d3;
		font: 700 24px/32px @headline-font;
		}
		
	.slot {
		margin: 0;
		padding: 20px 30px 30px;
		background: #fff;
		border-top: 1px solid #d3d2d3;
		}
	
	.text {
		margin: 0;
		padding: 0 30px 0 0;
		float: left;
		width: 580px;
		
		span {
			text-transform: uppercase;
			font: 400 14px/22px @body-font-2;
			padding: 0 0 5px;
			display: block;
			}
		
		p {
			padding: 0;
			font: 400 15px/22px @body-font;
			}
		
		}
		
	.booking {
		float: left;
		width: 270px;
		
		p {
			font: 400 14px/20px @body-font;
			}
	}
		
	h3 {
		font: 700 24px/32px @headline-font;
		padding: 0 0 15px;
		}
		
	.radio label {
		background: @cp-color-1;
		border: 6px solid @cp-color-1;
		padding-right: 20px;
		border-radius: 20px;
		color: white;
		font: 700 16px/27px @headline-font;
		position: relative;
		color: @text-color;
		
		&:before {
			background-color: white !important;
			border: 0 !important;
			top: 0;
			width: 25px;
			height: 25px;
		}
		
		
	
		&.checked {
			//background: @bg-color-2;
			//border-color: @bg-color-2;
			//color: white;

			&:before {
				border: 0 !important;
			}
		
			&:after {
				content: "";
				position: absolute;
				background: #000;
				height: 15px;
				width: 15px;
				left: 5px;
				top: 5px;
				border-radius: 50%;
			}
		}
		
		&:hover {
			
			&:after {
				content: "";
				position: absolute;
				background: @bg-color-2;
				height: 15px;
				width: 15px;
				left: 5px;
				top: 5px;
				border-radius: 50%;
			}

			&.checked {
				&:before {
					border: 0 !important;
				}
				&:after {
					background: rgba(0,0,0,0.8);
				}
			}
		}
	}
	
	.radio.disabled label {
		background: #d3d3d3;
		border-color: #d3d3d3;
		cursor: default;
		pointer-events: none;
		padding: 0 27px;
		
		&:before {
			background: #d3d3d3 !important;
		}
	
	}
	
	.button.edit {
		border-radius: 20px;
		color: @text-color;
		height: 34px;
		padding: 0px 20px;
		font: 400 14px/34px @body-font-2;
		text-shadow: none;
		
		&:hover {
			background: @text-color;
			color: white;
		}
	}
	
	.facts {
		border: 0;
		width: 570px;
		padding: 0 40px 0 0;
		float: left;
	
		.fact {
			border: 0;
			padding: 0 0 10px;
			
			h4 {
				width: 35%;
				font: 400 14px/20px @body-font-2;
			}
			
			p {
				font: 400 14px/20px @body-font;
			}
			
			.text {
				padding: 0;
				width: 65%;
			}
			
			&:last-child {
				padding: 0;
			}
			
		}
		
	}

}


#travelData {

	#travelNumbers {
		float: left;
		margin: 0 20px 0 0;
		width: 400px;
		
		label:first-child {
			margin: 10px 0 10px 0;
			width:110px;
			padding:0;
		}
		
		input {
			width:140px;
			margin: 0 10px 0 0;
		}
		
		label:last-child {
			margin: 10px 10px 10px 0;
		}
		
	}
	
	#travelDates {
		float: left;
		width: 250px;
		
		label {
			margin: 10px 10px 10px 0;
			padding:0;
		}
		
		input {
			width:140px;
			margin: 0 10px 0 0;
		}
	}
	
}




@media screen and ( max-width: 959px )  {
	
	#seminarForm .formLeft,
	#seminarForm .formRight {
		width: 100%;
	}
	
	#seminarForm .formRight textarea {
		
		height: 230px;
				
	}
	
}


@media screen and ( max-width: 639px )  {

	.seminar .text {
		width: 100%;
		float: none;
	}
	
	.tooltip {
		color: @text-color;
		
		span {
			position: relative;
			background:none;
			color: #aaa !important;
			right: auto;
			bottom: auto;
			width: auto;
			display: block;
			padding: 5px 0 0;
			// padding: 20px;
			font: 400 12px/18px @body-font;
			
			&:before {
				display: none;
			}
		
		}
	}
	
	
}


@media screen and ( max-width: 479px )  {

	.seminar .text p {
		/* display: none; */
		padding: 0 0 10px;
	}
	
	.seminar .facts {
		width: 100%;
		padding: 0;
		
		.fact {
			.text {
				width: 100%;
			}
		}
		
		.fact h4 {
			padding: 0;
		}
	}
	
}
