.top {
	margin: 0 10px 40px;
	padding: 0 0 0 22px;
	display: inline-block;
	position: relative;
	font: 400 14px/20px @body-font;
	color: @text-color;	
	
	display: none !important;
	

	.icon-arrow_up;
	
	&:before {
		// color: @cp-color-2;
		font-size: .3125em;
		position: absolute;
		left: 0;
	}

	@media screen and (min-width: 960px )  {
	
		&:hover {
			color: @cp-color-2;
		}
	}
}

@media screen and ( min-width: 960px )  {
	
	.top {
    	margin: 0 20px 40px;
	}
}

.top-last-child {
	top: -22px;
	margin-bottom: 18px;
}


@media screen and ( max-width: 639px )  {

	.top-last-child {
		top: 0;
	}
}



.anchors ul {
	list-style: none;
	margin: 0 0 40px 10px;
	padding: 0;
	
	li {
		margin: 5px 0 !important;
	}
	
}

@media screen and ( min-width: 960px )  {
	
	.anchors ul {
    	margin: 0 0 40px 20px;
	}
}

.anchor {
	display: block;
	position: relative;
    padding: 0 0 0 22px;
	color: @text-color;
	
	.icon-arrow_down;
	
	&:before {
		// color: @cp-color-2;
		font-size: .3125em;
		position: absolute;
		left: 0px;
		top: 1px;
	}
	
	@media screen and (min-width: 960px )  {

		&:hover {
			color: @cp-color-2;
		}
	}
}