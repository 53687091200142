.notice {
	margin: 0 10px 40px;
	// padding: 25px 25px 5px;
	padding: 0;
	// background: @cp-color-1;
	display: block;
	position: relative;
	// min-height: 120px;
	min-height: 100px;
	
	@media screen and (min-width: 960px )  {
		margin: 0 20px 40px;
	}
	
	h1 {
		margin: 0;
		color: @text-color;
	}
	
	p {
		font: 400 ~"16px/24px" @body-font;
		color: @text-color;
		padding-bottom: 20px;
		
		a {
			color: @text-color;
			display: inline-block;
			padding-left: 18px;
			background: url("@{path}/icon_arrow_right_white.svg") left 2px no-repeat;
			
			&:hover {
				color: @cp-color-2;
				background: url("@{path}/icon_arrow_right_black.svg") left 2px no-repeat;
			}
		}	
	}
}

.form .notice {
	margin: 0;
}


.notice.success {
	// padding-left: 150px;
	padding-left: 125px;
	
	&:before {
		content: "";
		position: absolute;
		// left: 25px;
		left: 0;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		background: white url("@{path}/icon_process_ok.svg") center center no-repeat;
		background-size: 50px auto;
	}
	
	h2 {
		margin: 0;
		padding: 10px 0;
	}
	
	
	
}



.notice.email {
	// padding-left: 150px;
	padding-left: 125px;
	
	&:before {
		content: "";
		position: absolute;
		// left: 25px;
		left: 0;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		background: white url("@{path}/icon_mail.svg") center center no-repeat;
		background-size: 50px auto;
	}
	
	h2 {
		margin: 0;
		padding: 10px 0;
	}
	
}


.notice.error {
	background: red;
	min-height: 10px;
	padding: 25px 30px 5px;
	margin-bottom: 20px;
	
	p {
		color: white;
		font-family: Arial;
		font-weight: 700;
	}
	
}



.form fieldset .notice.error {
	margin: -30px -30px 20px;
	border: 0;
}