/* MOBILE */

.nav-mobile {
	position: absolute;

	// Android bugfix
	@-webkit-keyframes fixAndroidBug {
		from {
			padding: 0;
		}

		to {
			padding: 0;
		}
	}
}

@media screen and (min-width: 959px) {
	.nav-mobile {
		display: none;
	}
}

.slide-nav-checkbox,
.slide-nav-label-root {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
}

.slide-nav-root,
.slide-nav-sub {
	width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	right: auto;
	left: 100%;
	background-color: #fff;
	z-index: 1400;
	overflow-x: hidden;
	overflow-y: auto;
	transform: translateZ(0);
	transition: transform 0.2s ease;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: 0;
}

.slide-nav-checkbox:checked ~ .slide-nav-root,
.slide-nav-root .slide-nav-checkbox:checked ~ .slide-nav-sub {
	transform: translate3d(-100%, 0, 0);
}

.slide-nav-checkbox:checked ~ .slide-nav-root a,
.slide-nav-root .slide-nav-checkbox:checked ~ .slide-nav-sub a {
	visibility: visible !important;
}

.slide-nav-sub {
	position: absolute;
	bottom: 0;
}

.slide-nav-root {
	min-height: calc(100%-4.5rem);
	background-color: #fff;
	max-width: 400px;
}

.slide-nav-root ul {
	text-decoration: none;
	list-style: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.slide-nav-root a,
.slide-nav-root button,
.slide-nav-root span {
	display: block;
	text-decoration: none;
}

.slide-nav-root .slide-nav-label-sub {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	right: 10px;
	bottom: 0;
}

.slide-nav-root .slide-nav-label-back,
.slide-nav-root .slide-nav-root-heading {
	font-size: 1.125rem;
	line-height: 1.44444;
	font-weight: 700;
	font: 700 18px/24px 'Zilla Slab', serif;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 0.5rem;
	overflow: visible;
	height: 4.5rem;
	background-color: #fff;
	box-shadow: 0 5px 16px 0 rgba(26, 46, 74, 0.15);
	color: #000;
}

.slide-nav-root .slide-nav-root-heading {
	padding-left: 2rem;
	line-height: 1;
	text-transform: uppercase;
}

.slide-nav-root .slide-nav-label-close {
	position: absolute;
	right: 14px;
	top: 12px;
	cursor: pointer;
	height: 3rem;
	width: 3rem;
	color: #000;
}

.slide-nav-label-back .icon {
	transform: translateY(-50%) rotate(-180deg);
}

.slide-nav-item-meta > a,
.slide-nav-item-meta > button,
.slide-nav-item-meta > span,
.slide-nav-item > a,
.slide-nav-item > button,
.slide-nav-item > span,
.slide-nav-sub-heading > a,
.slide-nav-sub-heading > button,
.slide-nav-sub-heading > span,
.slide-nav-sub-item > a,
.slide-nav-sub-item > button,
.slide-nav-sub-item > span {
	font-size: 1.125rem;
	appearance: none;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	line-height: inherit;
	font-weight: 700;
	color: #000;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	font: 700 18px/24px 'Zilla Slab', serif;
	text-transform: uppercase;
}

.slide-nav-item-meta > a::after,
.slide-nav-item-meta > button::after,
.slide-nav-item-meta > span::after,
.slide-nav-item > a::after,
.slide-nav-item > button::after,
.slide-nav-item > span::after,
.slide-nav-sub-heading > a::after,
.slide-nav-sub-heading > button::after,
.slide-nav-sub-heading > span::after,
.slide-nav-sub-item > a::after,
.slide-nav-sub-item > button::after,
.slide-nav-sub-item > span::after {
	content: '';
	display: block;
	height: 1px;
	position: absolute;
	left: 20px;
	right: 20px;
	bottom: 0;
	background-color: #d3d2d3;
}

.slide-nav-item--meta.is-first {
	margin-top: 0.8rem;
}

.slide-nav-item--meta > a,
.slide-nav-item--meta > button,
.slide-nav-item--meta > span {
	font-weight: 400;
	font-size: 0.9375rem;
	line-height: 1.6;
	text-transform: none;
	padding: 0.8rem 2rem 0.8rem 0.5rem;
}

.slide-nav-sub-item > a,
.slide-nav-sub-item > span {
	font-size: 15px;
	line-height: 21px;
	font-weight: 700;
	color: #000;
	font-family: 'Roboto', Arial;
	text-transform: none;
}

.slide-nav-sub-item.is-current > a {
	font-weight: 700;
}

.slide-nav-sub-heading > a::after,
.slide-nav-sub-heading > button::after,
.slide-nav-sub-heading > span::after {
	background-color: @cp-color-1;
	height: 7px;
	text-transform: uppercase;
}

.slide-nav-item-meta > a,
.slide-nav-item-meta > button,
.slide-nav-item-meta > span {
	min-height: auto;
	text-transform: none;
	font: 400 14px/20px 'Roboto', sans-serif;
	color: #a1a1a1;
	padding: 10px 20px;

	&::after {
		display: none !important;
	}
}

.slide-nav-checkbox:checked ~ .slide-nav-root {
	box-shadow: 0 10px 50px rgba(26, 46, 74, 0.25);
}

.slide-nav-item-meta.is-current > a,
.slide-nav-item-meta > .is-current,
.slide-nav-item.is-current > a,
.slide-nav-item > .is-current,
.slide-nav-sub-item.is-current > a,
.slide-nav-sub-item > .is-current {
	color: @cp-color-2;
}

.slide-nav-label {
	text-indent: -9999em;
	overflow: hidden;
	margin: 0;
	padding-bottom: 0;
}

.slide-nav-label,
.slide-nav-label-back,
.slide-nav-label-close,
.slide-nav-label-root,
.slide-nav-label-sub {
	display: inline-block;
	position: relative;
}

.slide-nav-label-sub {
	width: auto;
}

.slide-nav-root .slide-nav-label-back {
	padding-left: 4.5rem;
	text-indent: 0;
	font-size: 15px;
	line-height: 21px;
	font-weight: 700;
	color: @text-color;
	font-family: 'Roboto', Arial;
}

.slide-nav-label-close-icon,
.slide-nav-label-root-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
}

.slide-nav-label-close-icon {
	color: #000;
	font-size: 2rem;
	padding: 4px;
}

.slide-nav-label-back-icon,
.slide-nav-label-sub-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1.5rem;
}

.slide-nav-label-sub-icon {
	right: 10px;
	background: @cp-color-1;
	padding: 8px;
	color: white;
}

.slide-nav-label-back-icon {
	left: 2rem;
}

.slide-nav-is-open {
	overflow: hidden;
	height: 100%;
}
