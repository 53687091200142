.fancybox-overlay {
	background: rgba(0,0,0,0.85) !important;
	}

.fancybox-overlay .fancybox-skin {
	box-shadow: none;
	background: #fff;
	border-radius: 0;
	}
	
	
.fancybox-title {
	text-align: left;
	}
	
.fancybox-title .text {
	margin: 0;
	min-height: 70px;
	}

.fancybox-title-inside-wrap {
	background: #fff;
    color: @text-color;
    font: 400 15px/22px @body-font;
    padding: 0;
	}

.fancybox-title-inside-wrap p {
    font: 400 15px/22px @body-font;
    padding: 20px 20px 20px 0;
	}
	
.fancybox-title-inside-wrap p.credits {
   background: rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;
    position: relative;
    top: -20px;
    float: right;
	font: 400 13px/20px @body-font;
	color: white;
	padding: 0 15px;
	z-index: 1;

	&:hover {
		background: #000;
	}
}

.fancybox-title-inside-wrap {
	
	a.download  {
		float: right;
		display: block;
		padding: 0 0 0 32px;
		width: 0;
		height: 32px;
		color: @text-color;
		overflow: hidden;
		position: absolute;
		right: 0px;
		margin: 30px 0px 0px;
		.icon-download;
		
		&:hover {			
			color: @cp-color-2;
		}
		
		&:before {
			position: absolute;
			left: 1px;
			top: 0;
			font-size: 32px;
			line-height: 100%;
		}
	}
	

}



#fancybox-buttons ul {
	border: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background: #000;
	}

#fancybox-buttons a {
	background-image: url("@{path}/fancybox_buttons.png");
	}

#fancybox-buttons.bottom {
	bottom: 20px;
	}
	
.fancybox-close {
	background: white url("@{path}/reset.png") center center no-repeat;
	width: 36px;
	height: 36px;
	border-radius: 18px;
	display: block;
	position: absolute;
	right: -18px;
	top: -18px;
	text-indent: -10000em;
	}

.fancybox-close:hover {
	background: @cp-color-3 url("@{path}/reset-white.png") center center no-repeat;
	}


// FANCYBOX
.gal-fancy {
	background-color: #fff;
	
	.fancybox-next span, .fancybox-prev span {
		background-image: url("@{path}/icon-gallery-sprite.png");
		height: 40px;
		width: 40px;
		background-color: @cp-color-1;
	}
	
	.fancybox-next span {
		background-position: -81px 0;
		right: -20px;
	}
	
	.fancybox-prev span {
		background-position: -2px 0;
		left: -20px;
	}
	
}

/*
*/

#fancybox-buttons ul {
	height: 40px;
	width: 204px;
	background: transparent;
}

#fancybox-buttons ul li {
	margin-left: 1px;
}
#fancybox-buttons ul li:first-child {
	margin-left: 0;
}

#fancybox-buttons ul li a {
	background-image: url("@{path}/icon-gallery-sprite.png");
	height: 40px !important;
	width: 40px !important;
	border: none !important;
	opacity: 1 !important;
	background-color: @cp-color-1;
}
#fancybox-buttons ul li a:hover {
	background-color: @cp-color-2;
}

#fancybox-buttons ul li a.btnPrev {
    background-position: 0 0;
}

#fancybox-buttons ul li a.btnPlay {
    background-position: -41px 0px;
}

#fancybox-buttons ul li a.btnNext {
    background-position: -81px 0;
}

#fancybox-buttons ul li a.btnToggle {
	background-position: -122px 0;
}

#fancybox-buttons ul li a.btnClose {
	background-position: -165px 0;
}

#fancybox-buttons ul li a.btnPlayOn {
    background-position: -206px 0px;
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading div {
		background-image: url('@{path}/fancybox_loading@2x.gif');
		background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
	}
}