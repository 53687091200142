@media all {
	// RESET

	// Zurücksetzen der Innen- und Außenabstände für alle HTML-Elemente
	/* HO: Nicht besser alle Tags aufzählen hier wie bei meyer? */
	* {
		margin: 0;
		padding: 0;
	}

	// Korrektur: Das Zurücksetzen der Abstände verursacht zu kleine Selectboxen.
	option {
		padding-left: 0.4em;
	}

	select {
		padding: 1px;
	}

	// Alle Schriftgrößen auf Standardgröße (16 Pixel) zurücksetzen
	html {
		font-size: 100%;
	}

	// Beseitigung von Rundungsfehler beim Skalieren von Schriftgrößen in älteren Opera Versionen
	body {
		background: @body-bg;
		font-family: @body-font;
		font-size: @body-font-size;
		color: @body-font-color;
		text-align: left;
		overflow-y: scroll;
	}

	html {
		height: 100%;
	}

	body {
		height: 100%;
		overflow-y: auto; // Problem Chrome
	}

	// Vermeidung sichtbarer Outline-Rahmen in Webkit-Browsern
	div:target,
	h1:target,
	h2:target,
	h3:target,
	h4:target,
	h5:target,
	h6:target {
		outline: 0 none;
	}

	// HTML5 - Elements werden als Blockelemente definiert
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	main,
	nav,
	section,
	summary {
		display: block;
	}

	// HTML5 - Standard Eigenschaften für Media-Elemente
	audio,
	canvas,
	video {
		display: inline-block;
	}

	// HTML5 - <audio> ohne Kontrollelemente sollten nicht angezeigt werden
	audio:not([controls]) {
		display: none;
	}

	// HTML5 - Eigenschaften für das hidden-Attribut in älteren IEs und FF nachrüsten
	[hidden] {
		display: none;
	}

	// Verhindert die automatische Textanpassung bei Orientierungswechsel, ohne Zoom zu blockieren
	html {
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
	}

	// Setze das richtige Box-Modell im IE8/9 und entferne unnötiges Padding
	input[type='checkbox'],
	input[type='radio'] {
		box-sizing: border-box;
		padding: 0;
	}

	input[type='radio'] {
		margin-left: 1px;
	}

	// Einheitliches Erscheinungsbild für input[type="search"] Elemente erzwingen
	input[type='search'] {
		-webkit-appearance: textfield;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}

	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	// Korrigiert fehlerhafte overflow Voreinstellung des IE 9
	svg:not(:root) {
		overflow: hidden;
	}

	// Ergänzt fehlenden Margin in IE 8/9 und Safari 5
	img,
	figure {
		margin: 0;
	}

	// Rahmen für <fieldset> und <img> Elemente löschen
	fieldset,
	img {
		border: 0 solid;
	}

	// CONTENT-ELEMENTE

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: @headline-font;
		font-size: @headline-font-size;
		color: @headline-font-color;
		line-height: @headline-font-line-height;
		font-weight: @headline-font-weight;
		padding: 0 0 @content-margin-bottom 0;
		word-break: break-word;
	}

	p {
		font-size: @body-font-size;
		line-height: @body-font-line-height;
		padding: 0 0 @content-margin-bottom 0;
	}

	big {
		font-size: (@body-font-size + 2 * 1);
		line-height: @body-font-line-height;
	}

	small {
		font-size: (@body-font-size - 2 * 1);
		line-height: @body-font-line-height;
	}

	strong,
	b,
	kbd,
	samp,
	var,
	tt {
		font-weight: bold;
	}

	em,
	i,
	var,
	dfn {
		font-style: italic;
	}

	/* Schriftart von monospaced Elementen  */
	textarea,
	pre,
	code,
	kbd,
	samp,
	var,
	tt {
		font-family: @mono-font;
	}

	/* Schrift-Größe von monospaced Elementen  */
	pre,
	code,
	kbd,
	tt,
	samp,
	var {
		font-size: @body-font-size;
	}

	pre {
		font-size: @body-font-size;
		line-height: @body-font-line-height;
		padding: 0 0 @content-margin-bottom 0;
		white-space: pre;
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	acronym,
	abbr {
		border-bottom: @border;
		font-variant: small-caps;
		letter-spacing: 0.07em;
		cursor: help;
	}

	sub,
	sup {
		font-size: (@body-font-size - 2 * 1);
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}

	sup {
		top: -(@body-font-size / 2 * 1);
	}

	sub {
		bottom: -(@body-font-size / 4 * 1);
	}

	mark {
		background: yellow;
		color: black;
	}

	hr {
		color: transparent;
		background: transparent;
		margin: 0;
		padding: 0;
		border: 0;
		border-bottom: @border;
	}

	blockquote {
		background: transparent;
		color: @body-font-color;
		padding: @content-margin-bottom 0 @content-margin-bottom 0;
	}

	q {
		quotes: none;
	}

	blockquote,
	cite,
	q {
		font-style: italic;
	}

	blockquote::before,
	blockquote::after,
	q::before,
	q::after {
		content: '';
		content: none;
	}

	// Links

	a {
		color: @link-color;
		background: transparent;
		text-decoration: none;
	}

	@media screen and (min-width: 960px) {
		a:active,
		a:focus,
		a:hover {
			outline: none;
		}

		// Maximaler Kontrast für Tab Focus - Ändern Sie diese Regel mit Bedacht
		a:hover,
		a:focus {
			color: @link-color-hover;
			text-decoration: none;
		}
	}

	// LISTEN

	// Neue Standardwerte für Listen, Zitate und Tabellen
	ul,
	ol {
		font-size: @body-font-size;
		line-height: @body-font-line-height;
		margin: 0 0 @content-margin-bottom 13px;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	ul ul {
		margin-top: 0;
	}

	ol ol {
		list-style-type: lower-latin;
		margin-top: 0;
	}

	ol ul {
		list-style-type: circle;
		margin-top: 0;
	}

	li {
		font-size: @body-font-size;
		line-height: @body-font-line-height;
		margin-left: 10px;
	}

	dl {
		font-size: @body-font-size;
		line-height: @body-font-line-height;
		margin: 0 0 @content-margin-bottom 3px;
	}

	dt {
		font-weight: bold;
	}

	dd {
		margin: 0 0 @content-margin-bottom 20px;
	}

	// TABELLEN

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		margin: 0 0 @content-margin-bottom 0;
		border: @table-border;
		color: @table-font-color;
	}

	table thead th {
		background: @table-head-bg;
		color: @table-font-color-head;
		border-right: @table-border;
		border-bottom: @table-border;
	}

	table tbody th[scope='row'] {
		background: @table-head-bg;
	}

	table tbody th {
		border-right: @table-border;
	}

	table tbody td {
		border-right: @table-border;
	}

	caption {
		font-variant: small-caps;
	}

	th,
	td {
		line-height: @body-font-line-height;
		vertical-align: top;
		padding: @table-padding;
	}

	th *:first-child,
	td *:first-child {
		margin-top: 0;
	}

	thead th {
		text-align: left;
	}

	tbody th {
		text-align: left;
		border-top: @table-border;
	}

	tbody td {
		text-align: left;
		border-top: @table-border;
	}

	tbody tr {
		background: @table-bg;
	}

	@media screen and (min-width: 960px) {
		tbody tr:hover th,
		tbody tr:hover td {
			background: @table-bg-hover;
			color: @table-font-color-hover;
		}
	}
}

// retina+
@media screen and (-webkit-min-device-pixel-ratio: 2),
	screen and (min-resolution: 192dpi),
	screen and (min-resolution: 2dppx) {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@media print {
	// Wechsel der der Schriftgrößen-Maßheinheit zu [pt] - Probleme mit [px] in Gecko-Browsern vermeiden
	body {
		font-size: (1pt * @body-font-size);
		padding: 0 5px;
	}

	// Vermeidung von Seitenumbrüchen direkt nach einer Überschrift
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
	}
}
