.cc-revoke {
	display: none !important;
}

.cc-window {
	font-family: "Roboto Regular", Arial, sans-serif !important;
	font-size: 1rem !important;
}

.cc-window.cc-floating {
    padding: 1.5em !important;
    box-shadow: -5px 5px 25px 0 rgba(26,46,74,.25);
}


.dp--cookie-check {
	border-radius: 0 !important;
	border: 0 !important;
	justify-content: flex-start !important;
}

.dp--cookie-check label {
	padding: 10px !important;
	position: relative;
	cursor: pointer;
	transition: all .2s ease;
	display: inline-block !important;
	font-weight: bold;
	
	
	&:first-child {
		padding-left: 0 !important;
	}
}

.cc-compliance {
	flex-direction: column;
}

.cc-btn {
	border-width: 2px !important;
	font-size: 1em !important;
}

.cc-btn.cc-allow {
    order: 1 !important;
    font-weight: normal !important;
}

.cc-btn.cc-dismiss {
	padding: 0.75em 1.5em !important;
	margin-left: .5rem !important;
	margin-bottom: .5rem !important;
	
	&:hover,
	&:focus {
		background: transparent !important;
		border-color: #000 !important;
		color: #000 !important;
		text-decoration: none !important;
	}
}


.cc-highlight .cc-btn:first-child {
	border-color: transparent !important;
	color: rgb(0,0,0) !important;
}

.dp--cookie-check label[for="dp--cookie-marketing"] {
	display: none !important;
}

.revoke-cookieconsent:hover,
.revoke-cookieconsent:focus {
	cursor: pointer;
}