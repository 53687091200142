.form {
	margin: 0 10px 40px;
	clear: both;
	overflow: hidden;
}

@media screen and (min-width: 960px) {
	.form {
		margin: 0 20px 40px;
	}
}

.form .item {
	margin: 0 0 20px;
	position: relative;
}

.form h2 {
	margin: 0;
	padding-bottom: 30px;
}

.form h3 {
	margin: 0 0 20px;
}

fieldset {
	padding: 30px 30px 40px;
	background: white;
	margin: 0 0 20px;
}

legend {
	font: 400 ~'24px/28px' @headline-font;
	color: @cp-color-1;
	margin: 0 0 30px;
}

label {
	font: 400 16px/24px @body-font-2;
	color: @text-color;
	padding: 0 0 5px;
}

label span.info {
	font: 400 12px/22px @body-font;
	color: #9fa0a0;
}

input,
textarea,
select {
	padding: 10px;
	font: 400 16px/22px @body-font;
	background: #f6f6f6 !important;
	border: 1px solid #d3d2d3;
	box-shadow: none;
	border-radius: 0;
}

select {
	padding: 9px;
}

textarea {
	height: 150px;
	resize: none;
}

input:hover,
select:hover,
textarea:hover {
	border: 1px solid #a1a1a1;
	background: #f9f9f9 !important;
}

input:focus,
select:focus,
textarea:focus,
input:active,
select:active,
textarea:active {
	border: 1px solid #a1a1a1;
	background: #f9f9f9 !important;
}

.checkbox,
.radio {
	position: relative;
}

.checkbox input,
.radio input {
	position: absolute;
	left: -10000em;
}

.checkbox label,
.radio label {
	padding: 0 0 0 33px;
	font: 400 14px/22px @body-font;
	margin: 0 20px 0 0 !important;
	display: inline-block;
	position: relative;

	a {
		border-bottom: 1px dotted @cp-color-2;

		&:hover,
		&:active,
		&:focus {
			color: @text-color;
			border-bottom: 1px dotted #a1a1a1;
		}
	}

	&::before {
		width: 22px;
		height: 22px;
		background: #f6f6f6;
		border: 1px solid #d3d2d3;
		content: '';
		position: absolute;
		left: 0;
		top: -1px;
		border-radius: 0;
	}

	&.checked::before {
		border: 1px solid @cp-color-1;
		background: @cp-color-1 url('@{path}/checkbox.png') center center no-repeat;
	}

	&:hover {
		&::before {
			background-color: #f9f9f9;
			border: 1px solid #a1a1a1 !important;
		}

		&.checked::before {
			background-color: @text-color;
			border: 1px solid @text-color !important;
		}
	}
}

.radio label {
	&::before {
		border-radius: 15px;
	}

	&.checked::before {
		//border: 1px solid #d3d2d3;
		//background: url("@{path}/radio.png") center center no-repeat;
	}
}

.tooltip {
	position: relative;
	color: @text-color;
	border-bottom: 1px dotted @text-color;

	span {
		position: absolute;
		background: rgba(0, 0, 0, 0.85);
		color: white !important;
		border-radius: 3px;
		left: -30px;
		bottom: 40px;
		width: 320px;
		display: none;
		padding: 15px 20px;
		font: 400 14px/20px @body-font;

		&::before {
			content: '';
			display: block;
			position: absolute;
			border-color: rgba(0, 0, 0, 0.85) transparent transparent;
			border-style: solid;
			bottom: -20px;
			left: 40px;
			border-width: 20px 20px 0 0;
		}

		a {
			border-bottom: 1px dotted @cp-color-2;

			&:hover,
			&:active,
			&:focus {
				color: white;
				border-bottom: 1px dotted white;
			}
		}
	}

	&:hover {
		color: @text-color;

		span {
			display: block;
		}
	}
}

.showField {
	margin-bottom: 0 !important;

	label {
		margin: 0 !important;
		font-size: 14px;
		line-height: 16px;
		color: #9fa0a0;
		padding: 0 0 0 24px;

		&:hover {
			color: @text-color;
		}

		&::before {
			width: 16px;
			height: 16px;
			background-size: 70% auto !important;
		}
	}
}

.itemSplit .showField {
	label {
		margin: 40px 0 0 !important;
	}
}

.parsley-errors-list {
	margin-bottom: 0;
}

/* Specials */

/* Kontaktform */
.parsley-error #parsley-id-multiple-salutation {
	top: -15px;
	position: relative;
}

/* Seminar - Bundesland */
.select2-container.parsley-error .select2-choice {
	border-color: red;
}

.mandatory {
	font: 400 12px/24px @body-font;
	color: #9fa0a0;
	text-align: left;
}

.form .submit {
	text-align: right;
	margin: 0;

	.mandatory {
		float: left;
		padding-top: 12px;
	}
}

.form .itemSplit .item {
	float: left;
	margin-bottom: 20px !important;
	padding: 0 0 0 20px;
}

.form .itemSplit:last-child .item {
	margin: 0 !important;
}

.form .itemSplit .item:first-child {
	padding: 0 !important;
}

.form .item20 {
	width: 90px;
}

.form .item30 {
	width: 145px;
}

.form .item40 {
	width: 215px;
}

.form .item50 {
	width: 270px;
}

.form .item60 {
	width: 325px;
}

.form .item70 {
	width: 395px;
}

.form .item80 {
	width: 450px;
}

.form .item100 {
	width: 560px;
}

.formStacked .info {
	float: right;
	margin: 0 -300px 0 0;
	width: 280px;
}

.formStacked fieldset {
	margin-right: 300px;
}

.formStacked fieldset:first-child {
	margin-right: 0;
	padding-right: 300px;
}

.formStacked fieldset:last-child {
	margin: 0;
	padding-bottom: 10px;
}

.formStacked .select2-container {
	width: 620px;
}

.formInline {
	label,
	input,
	textarea,
	button,
	select {
		display: inline-block;
		margin: 0 20px 20px 0;
		width: auto;
		float: left;
	}

	.checkbox,
	.radio {
		float: left;
	}
}

.formAligned {
	.item {
		margin: 0 0 10px;

		&::before {
			content: '';
			display: table;
		}

		&::after {
			clear: both;
			content: '.';
			display: block;
			font-size: 0;
			height: 0;
			visibility: hidden;
		}
	}

	label,
	input,
	textarea,
	button,
	select {
		display: inline-block;
	}

	label {
		width: 225px;
		float: left;
	}

	input,
	textarea {
		width: 400px;
		float: left;
	}
}

@media screen and (max-width: 639px) {
	.itemSplit .showField label {
		margin: 0 !important;
	}

	.form .item {
		width: 100%;
	}

	.form .itemSplit .item {
		float: none;
		padding: 0;
		margin-bottom: 15px;
	}

	.formInline .checkbox,
	.formInline .radio {
		width: auto;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}



.error,
.error p,
.error li {
	color: red;
	}

.parsley-errors-list {
	list-style: none;
	margin: 0;
	clear: both;
	/*
	position: absolute;
	right: 0px;
	*/
	
	&.filled {
		margin: 3px 0 0;
	}

	li {
		font: 400 13px/18px @body-font;
		color: red;
		margin: 0;
		background: transparent;
	}
}

.checkbox.parsley-error {
	.parsley-errors-list {
		position: relative;
		right: auto;
	}
}


input.error,
input.parsley-error,
textarea.error,
textarea.parsley-error {
	border-color: red;
	/* color: red; */
}


.parsley-error {

	.radio label {

		&:before {
			border-color: red;
		}
	}
}
