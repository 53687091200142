.select2-container {
	width: 100%;
}

.select2-container .select2-choice,
.select2-container-multi .select2-choices {
	font: 400 16px/22px @body-font;
	background: #f6f6f6 !important;
	border: 1px solid #d3d2d3;
	text-align: left;
	color: @text-color;
	height: 22px;
	padding: 10px;
	margin: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	box-shadow: none !important;
}

.select2-container-multi .select2-choices {
	padding: 4px;
	min-height: 20px;
	height: auto;
}

.select2-container .select2-choice .select2-arrow {
	background: none;
	border: 0;
	border-radius: 0;
	width: 30px;
}

.select2-container .select2-choice .select2-arrow b {
	background-image: none;
	.icon-arrow_down;

	&::before {
		font-size: 6px;
		position: absolute;
		left: 0;
		top: 10px;
	}
}

.select2-container .select2-choice:hover .select2-arrow b {
	background-image: none;
}

.select2-container .select2-choice:hover,
.select2-container-multi .select2-choices:hover {
	/* OLD
	background: @bg-color-3 !important;
	*/
	color: @text-color;
	background-color: #f9f9f9 !important;
	border: 1px solid #a1a1a1 !important;
}

/*
.select2-container .select2-choice:hover .select2-arrow b {
	background: url("@{path}/icon_arrow_down_black.svg") center center no-repeat;
	background-size: 12px 5px;
}
*/

/*

.select2-container-multi .select2-choices .select2-search-choice {
	background: #d7e8bd;
	font: 400 14px/20px @body-font;
	box-shadow: none;
	border: none;
	margin: 3px;
	}

*/

.select2-container.select2-allowclear .select2-choice .select2-chosen {
	margin-right: 60px;
}

.select2-container .select2-choice abbr.select2-search-choice-close {
	width: 17px !important;
	height: 17px !important;
	right: 36px !important;
	top: 10px !important;
	color: #d3d2d3;
	.icon-close;

	&::before {
		font-size: 10px;
	}

	&:hover {
		color: @cp-color-2;
	}
}

.select2-container.select2-drop-above {
	z-index: 10000;
}

.select2-dropdown-open .select2-choice {
	border-bottom: 0;
	-webkit-border-radius: 2px 2px 0 0;
	border-radius: 2px 2px 0 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.select2-dropdown-open .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices,
.select2-container-multi.select2-container-active .select2-choices {
	background: white;
	border: 1px solid #a1a1a1 !important;
}

.select2-dropdown-open.select2-drop-above .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice {
	border-radius: 0 0 2px 2px;
	-webkit-border-radius: 0;
	border-radius: 0;
	border-top: 1px solid #d3d2d3 !important;
}

.select2-drop {
	box-shadow: none !important;
	color: @text-color;
	background: white;
	-webkit-border-radius: 0 0 2px 2px;
	border-radius: 0 0 2px 2px;
	-webkit-border-radius: 0;
	border-radius: 0;
	border: 1px solid #a1a1a1 !important;
	border-top: 0 !important;
	margin-top: -1px;
}

.select2-drop.select2-drop-above.select2-drop-active {
	border: 1px solid #a1a1a1 !important;
	border-bottom: 0 !important;
	padding-top: 0;
	-webkit-border-radius: 2px 2px 0 0;
	border-radius: 2px 2px 0 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin: 0;
}

.select2-results {
	margin: 0;
	padding: 0;
	max-height: 240px;
}

.select2-results li {
	margin: 0;
}

.select2-results .select2-result-label {
	padding: 10px;
	font: 400 16px/22px @body-font;
	color: @text-color;
	border-bottom: 1px solid #efefef;
}

.select2-results li:last-child .select2-result-label {
	border: 0;
}

.select2-results .select2-highlighted {
	background: transparent;
}

.select2-results .select2-highlighted .select2-result-label {
	color: @cp-color-2 !important;
}

.select2-search {
	padding: 0;
	position: relative;
	display: block;
	.icon-lupe;

	&::before {
		position: absolute;
		right: 14px;
		top: 9px;
		font-size: 15px;
		z-index: 111;
	}
}

.select2-search input {
	font: 400 16px/22px @body-font;
	box-shadow: none !important;
	padding: 6px 10px;
	border: 1px solid #d3d2d3;
	border-left: 0;
	border-right: 0;
	margin: 0 !important;
	background: white !important;
}

.select2-drop.select2-drop-above .select2-search input {
	border-top: 0;
}

.select2-no-results {
	font: 400 16px/22px @body-font;
	padding: 6px 10px;
	color: white;
	background: @cp-color-2 !important;
}

.select2-container-multi .select2-choices {
	padding: 4px 32px 4px 4px;
	min-height: 22px;
	height: auto;
	.icon-arrow_down;
}

.select2-container-multi .select2-choices::before {
	color: #999;
	font-size: 6px;
	position: absolute;
	right: 13px;
	top: 10px;
}

.select2-container-multi .select2-choices .select2-search-choice {
	background: #ddd;
	font: 400 13px/22px @body-font;
	box-shadow: none;
	border: none;
	margin: 3px;
	padding: 2px 10px 2px 22px;
}

.select2-container-multi .select2-choices .select2-search-field input {
	font: 400 15px/22px @body-font;
	padding: 4px;
}

.select2-container-multi .select2-search-choice-close {
	border-radius: 10px;
	width: 13px;
	height: 13px;
	background: #fff;
	top: 7px;
	left: 4px;
	.icon-close;
	line-height: 13px;
	color: #999;

	&::before {
		font-size: 6px;
		line-height: 13px;
		position: absolute;
		left: 4px;
		top: 0;
	}

	&:hover {
		color: @cp-color-2;
	}
}
