.picker__header {
	clear: both;
	overflow: hidden;
	margin: 0;
	padding: 10px 30px 0;

	select {
		float: left;
		margin: 0;
		height: 40px;
	}

	.picker__select--year {
		margin-left: 10px;
		min-width: 65px;
		font-size: 14px;
	}

	.picker__select--month {
		margin-right: 0;
		min-width: 75px;
		float: right;
		font-size: 14px;
	}
}

.picker__month,
.picker__year {
	line-height: 35px;
}

.picker__nav--prev,
.picker__nav--next {
	top: 12px;
}

.picker__nav--prev {
	left: -15px;
}

.picker__nav--next {
	right: -15px;
}

.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted,
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
	background: #fff;
}

.picker__day {
	text-align: center;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
	color: #000;
}

.picker__weekday {
	color: #fff;
	text-align: center;
}

.picker__button--close,
.picker__button--close:hover {
	background: #fff;
	color: #000;
	text-shadow: none !important;
	border: 1px solid #000 !important;
	margin-bottom: 20px;
	width: auto;
	padding: 10px 50px;
	float: none !important;
	margin: 10px auto !important;
}

.picker__nav--prev:hover,
.picker__nav--next:hover {
	background: #fff;
}

.picker__button--close::before {
	color: #000;
	top: 0;
}

.picker__box,
.picker__wrap,
.picker__frame,
.picker__holder,
.picker {
	outline: none !important;
}

.picker__holder {
	border: 1px solid #000 !important;
}

.picker--opened .picker__holder {
	overflow: hidden;
}

.picker__nav--next {
	.icon-arrow_right;

	&::before {
		border-top: 0 !important;
		border-right: none !important;
		border-left: none !important;
	}
}

.picker__nav--prev {
	.icon-arrow_left;

	&::before {
		border-top: 0 !important;
		border-right: none !important;
		border-left: none !important;
	}
}

.picker__nav--disabled {
	color: #ccc;
}
