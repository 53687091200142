/* META */

.nav-meta__list-level-1 {
	list-style-type: none;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	padding: 0;
}

.nav-meta__item-level-1 {
	margin: 0;
}

.nav-meta__link-level-1 {
	appearance: none;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	font: 400 ~'14px/20px' @body-font;
	color: #a1a1a1;
	display: inline-block;
	position: relative;
	padding: 0.75em 1em;

	&:hover,
	&:focus,
	&:active {
		color: @cp-color-2;
	}
}

.nav-meta__item-level-1:last-of-type .nav-meta__link-level-1 {
	padding-right: 2em;
	.icon-lupe;

	&::before {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.nav-meta--exhibition .nav-meta__item-level-1:last-of-type .nav-meta__link-level-1 {
	padding-right: 1em;

	&::before {
		display: none;
	}
}
