#service {
	padding: 20px;
	width: 100%;
	border-bottom: 1px solid #e6e6e6;

	@media screen and (min-width: 959px) {
		border-bottom: 0;
		float: left;
		padding: 65px 20px;
		width: 33.33%;
		box-sizing: border-box;
		border-right: 1px solid #e6e6e6;
		min-height: 270px;
	}
}

#service ul {
	list-style: none;
	margin: 0;
	display: block;
}

#service li {
	list-style: none;
	margin: 0;
	font: 400 ~'16px/24px' @body-font;
}

#service li a,
#service li span,
#service li .legal-links__link {
	display: block;
	color: @text-color;
	padding: 2px 0;
	font: 400 ~'16px/24px' @body-font;
	cursor: pointer;
}

#service li .legal-links__link:hover,
#service li .legal-links__link:focus,
#service li .legal-links__link.active,
#service li a:hover,
#service li a:focus,
#service li a.active,
#service li span:hover,
#service li span:focus,
#service li span.active {
	color: @cp-color-2;
}
