

.statement {
	color: white;
	background: @cp-color-1;
	padding: 30px 40px 10px;
	
	.text {
		padding: 0;
	}
	
	.image {
		margin-bottom: 20px;
		
		img {
			width: 465px;
		}
	}
	
	p {
		font: 500 24px/30px @headline-font;
		color: @text-color;
	}
}

.transition-duration  (@duration: 0.7s) {

	@a: 0.25;
	@b: 0.46;
	@c: 0.45;
	@d: 0.94;

	-webkit-transition: all @duration cubic-bezier(@a, @b, @c, @d); 
   	-moz-transition: all @duration cubic-bezier(@a, @b, @c, @d); 
    -o-transition: all @duration cubic-bezier(@a, @b, @c, @d); 
    transition: all @duration cubic-bezier(@a, @b, @c, @d); /* easeOutExpo */

	-webkit-transition-timing-function: cubic-bezier(@a, @b, @c, @d); 
   	-moz-transition-timing-function: cubic-bezier(@a, @b, @c, @d); 
    -o-transition-timing-function: cubic-bezier(@a, @b, @c, @d); 
    transition-timing-function: cubic-bezier(@a, @b, @c, @d); /* easeOutExpo */
}

.blur (@blur: 3px) {
	-webkit-filter: blur(@blur);
	-moz-filter: blur(@blur);
	-o-filter: blur(@blur);
	-ms-filter: blur(@blur);
	filter: blur(@blur);
}

#statements {

	@path: "../../../assets/images/dlv_test_statements/";
	
	padding: 10px 0 0 0;
	width: 460px;
	height: 185px;
	position: relative;
	overflow: hidden;

	ul.sequence-canvas {
		padding: 0;
		margin: 0;
		width: 100%;
		height: 100%;

		> li {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li > * {
			position: absolute;
		}
		
		@taransform-deg: -8deg;

		li div {
			font: 700 20px/24px @headline-font;
			text-align: center;
			background: white;
			padding: 10px 10px 15px 10px;
			-ms-transform: skewX(@taransform-deg); /* IE 9 */
    		-webkit-transform: skewX(@taransform-deg); /* Chrome, Safari, Opera */
    		transform: skewX(@taransform-deg);
    		white-space: nowrap;

    		span {
    			display: block;
    			-ms-transform: skewX(-@taransform-deg); /* IE 9 */
    			-webkit-transform: skewX(-@taransform-deg); /* Chrome, Safari, Opera */
    			transform: skewX(-@taransform-deg);
    		}
		}
/*Sequenz: Gemeinschaft erleben*/
	
		.gemeinschaft {
			font-size: 45px;
			padding: 15px 10px;

			top: 50px;
			left: 460px;
			z-index: 6;
			
			opacity: 0;
			.blur(3px);
			.transition-duration();
		}

		.animate-in .gemeinschaft {
			left: 50px;			
			
			opacity: 1;
			.blur(0px);
		    .transition-duration();
		}

		.animate-out .gemeinschaft {
			left: 460px;
			
			opacity: 0;
			.blur(3px); 
			.transition-duration();
		}


		.erleben {
			font-size: 30px;
			padding: 10px;
			
			top: 100px;
			left: 580px;

			opacity: 0;
			.blur(3px);
		}
			
		.animate-in .erleben {
			left: 280px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}
		
		.animate-out .erleben {
			left: 580px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}

/*Sequenz: Wissen bewahren und weitergeben*/
@2top: 35px;

		.wissen-bewahren-und {
			font-size: 30px;
			padding: 15px 10px 15px 10px; 
			
			top: @2top;
			left: -340px;

			opacity: 0;
			.blur(3px);
		}

		.animate-in .wissen-bewahren-und {
			left: 15px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}

		.animate-out .wissen-bewahren-und {
			left: -340px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}

		.weitergeben {
			font-size: 45px;
			padding: 10px 10px 20px 10px;
			
			top: @2top + 50px;
			left: -460px;

			opacity: 0;
			.blur(3px);
		}

		.animate-in .weitergeben {
			left: 170px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}

		.animate-out .weitergeben {
			left: 460px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}

/*Sequenz: Frauen können und wollen mehr*/
@3top: 20px;

		.frauen  {
			font-size: 33px;
			padding: 10px;
						
			top: @3top;
			left: 460px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .frauen {
			left: 75px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}
		
		.animate-out .frauen {
			left: 460px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}

		.koennen-und {
			font-size: 45px;
			
			top: @3top + 37px;
			left: -280px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .koennen-und {
			left: 110px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}
		
		.animate-out .koennen-und {
			left: -280px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}

		.wollen-mehr {
			font-size: 45px;
			
			top: @3top + 80px;
			left: -300px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .wollen-mehr {
			left: 55px;

			opacity: 1;
			.blur(0px);
			.transition-duration ();
		}
		
		.animate-out .wollen-mehr {
			left: -300px;

			opacity: 0;
			.blur(3px);
			.transition-duration ();
		}


/*Sequenz 4: Dran bleiben lernen ein Leben lang*/
@4top: 25px;
@4left: 30px;
		
		.dran-bleiben {
			font-size: 33px;
			
			top: @4top;
			left: -225px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .dran-bleiben {
			left: @4left + 90px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}
		
		.animate-out .dran-bleiben {
			left: 685px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}
		
		.lernen {
			font-size: 45px;
			
			top: @4top + 35px;
			left: 460px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .lernen {
			left: @4left + 200px;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}
		
		.animate-out .lernen {
			left: 460px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}
		
		.ein-leben-lang {
			font-size: 45px;
			padding: 10px 10px 20px 10px;
			
			display: inline-block;
			width: 300px;
			
			top: @4top + 80px;
			left: 460px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .ein-leben-lang {
			left: @4left;

			opacity: 1;
			.blur(0px);
			.transition-duration();
		}
		
		.animate-out .ein-leben-lang {
			left: -340px;

			opacity: 0;
			.blur(3px);
			.transition-duration();
		}


/*Sequenz 5: Mehr erreichen gemeinsam türen öffnen */
@5left: 20px;
@5top: 15px;
		
		.mehr-erreichen {			
			font-size: 33px;
			display: inline-block;
			width: 250px;

			top: @5top;
			left: 460px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .mehr-erreichen {
			left: @5left + 60;

			opacity: 1;
			.blur(0px);
			.transition-duration ();
		}
		
		.animate-out .mehr-erreichen {
			left: 460px;

			opacity: 0;
			.blur(3px);
			.transition-duration ();
		}
		
		.gemeinsam {
			font-size: 45px;
			
			top: @5top + 40;
			left: -460px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .gemeinsam {
			left: @5left + 110;

			opacity: 1;
			.blur(0px);
			.transition-duration ();
		}
		
		.animate-out .gemeinsam {
			left: -460px;

			opacity: 0;
			.blur(3px);
			.transition-duration ();
		}
		
		.tueren-oeffnen {
			font-size: 45px;
			padding: 15px 10px 15px 10px;
			
			top: @5top + 80;
			left: -1000px;

			opacity: 0;
			.blur(3px);
		}
		
		.animate-in .tueren-oeffnen {
			left: @5left;

			opacity: 1;
			.blur(0px);
			.transition-duration ();
		}
		
		.animate-out .tueren-oeffnen {
			left: 460px;

			opacity: 0;
			.blur(3px);
			.transition-duration ();
		}

	}
}

@media screen and ( max-width: 959px )  {

	#statements {
		margin: 0 auto;
		width: auto;
		max-width: 460px;

		
	}

	.statement {

		img {
			max-width: 100%;
		}
	}
}


@media screen and ( max-width: 639px )  {
	
	.statement {
		margin-bottom: 40px !important;
		padding: 20px !important;
	}
}

@media screen and ( max-width: 479px )  {
	
	.statement {
		margin-bottom: 40px !important;
		padding: 20px 0 !important;
	}
	
	#statements {
		transform: scale(0.85);
		max-width: 100% !important;
		width: 100% !important;
		overflow: visible;
	}
	/*
	#statements ul.sequence-canvas li div span {
		font-size: 24px;
	}
	*/

	#statements ul.sequence-canvas .animate-in .erleben {
		left: 140px;
	}

	#statements ul.sequence-canvas .animate-in .weitergeben {
		left: 130px;
	}

	#statements ul.sequence-canvas .animate-in .dran-bleiben {
		left: 65px;
	}

	#statements ul.sequence-canvas .animate-in .lernen {
		left: 110px;
	}

	#statements ul.sequence-canvas .ein-leben-lang {
		// width: 200px;
	}

	#statements ul.sequence-canvas .animate-in .ein-leben-lang {
		left: 0;
	}

	#statements ul.sequence-canvas .animate-in .mehr-erreichen {
		left: 17px;
	}

	#statements ul.sequence-canvas .animate-in .gemeinsam {
		left: 110px;
	}

	#statements ul.sequence-canvas .animate-in .tueren-oeffnen {
		left: 60px;
	}
}

		
/*#statements {
	padding: 10px 0 0 0;
	width: 460px;
	height: 185px;
	overflow: hidden;
	position: relative;
	
	.image span {
		display: table;
		height: 175px;
		width: 100%;
		
		span {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
		}
	}
}*/

