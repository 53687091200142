.share {
	float: right;
	margin: 0 10px 40px;
	
	@media screen and (min-width: 960px) {
		margin: 0 20px 40px;
	}
	
	h4 {
		float: left;
		padding: 0 5px 0 20px;
		font: 400 14px/24px @body-font-2;
		color: @text-color;
	}
	
	a {
		float: left;
		display: inline-block;
		background: url("@{path}/share.png") left top no-repeat;
		height: 24px;
		width: 24px;
		text-indent: -10000em;
		margin: 0 0 0 8px;
	}
	
	a.twitter:hover {
		background-position: left -30px;
	}
	
	a.facebook {
		background-position: -31px top;
	}
	a.facebook:hover {
		background-position: -31px -30px;
	}
	a.gplus {
		background-position: -62px top;
	}
	a.gplus:hover {
		background-position: -62px -30px;
	}
	a.email {
		background-position: -93px top;
		margin-right: 26px;
	}
	a.email:hover {
		background-position: -93px -30px;
	}
	
	a.print {
		float: right;
		width: auto;
		text-indent: 0;
		padding: 0 30px 0 0;
		margin: 0;
		font: 400 14px/24px @body-font;
		color: @text-color;
		background: transparent;
		position: relative;
		
		&:before {
			content: "";
			background: url("@{path}/share.png") right 0px no-repeat;
			width: 24px;
			height: 24px;
			display: block;
			position: absolute;
			right: 0;
			top: 0;
        }
        
        &:hover {
			color: @cp-color-2;
	        
	        &:before {
				background-position: right -30px;
	        }
        }
	}
	
}

.share-last-child {
	margin-bottom: 0;
}

@media screen and ( max-width: 639px )  {
	
	.share {
		float: none;
		padding: 10px 0 15px !important;
		
		h4 {
			padding-left: 0;
		}

		h3 {
			padding-left: 0;
		}
		
		a {
			margin: 0 0 5px;
		}
	}
}