// Clearfix

.clearfix {
	&::before {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
		content: '.';
		display: block;
		font-size: 0;
		height: 0;
		visibility: hidden;
	}
}

// Visually hidden

.invisbible {
	position: absolute;
	top: -32768px;
	left: -32768px;

	&:focus,
	&:active {
		position: static;
		top: 0;
		left: 0;
	}
}

.sr-only {
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

// Background color

.bgYellow {
	background-color: @cp-color-1 !important;
}
.bgRed {
	background-color: @cp-color-2 !important;
}
.bgPatrol {
	background-color: @cp-color-3 !important;
}
.bgOrange {
	background-color: @cp-color-4 !important;
}
