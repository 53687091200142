/* --------------------------------

Back to top
triggers scroll to the top of the page

-------------------------------- */

.top-scroll {
	position: fixed;
	height: 46px;
	width: 46px;
	z-index: 999;
	transition: all 0.4s ease-out;
	right: 0;
	background-color: @cp-color-2;
	color: white;

	&:hover,
	&:active,
	&:focus {
		background-color: @text-color;
		color: white;
	}

	.icon {
		font-size: 2.2rem;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

// Hide back to top initially
// but only if JS is enabled

.js .top-scroll {
	bottom: -46px;
	visibility: hidden;
	opacity: 0;

	&.is-visible {
		bottom: 0;
		visibility: visible;
		opacity: 1;
	}
}
