.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	display: inline-block;
	white-space: nowrap;
	background-color: @button-bg;
	border: @button-border;
	color: @button-font-color;
	cursor: pointer;
	font: 400 1em/2em @button-font;
	margin: 0 0.75em 0 0;
	padding: @button-padding;
	overflow: visible;
	/* removes extra side spacing in IE */
	text-decoration: none;
	text-shadow: 0 1px 0 @button-bg;
}

.button:hover,
button:hover,
input[type='button']:hover,
input[type='reset']:hover,
input[type='submit']:hover {
	background-color: @button-bg-hover;
	text-shadow: 0 1px 0 @button-bg-hover;
}

.button:active,
button:active,
input[type='button']:active,
input[type='reset']:active,
input[type='submit']:active {
	background-color: @button-bg-hover;
}

.button:focus,
button:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='submit']:focus {
	outline: 0;
	background: @button-bg-hover;
}

/* removes extra inner spacing in Firefox */
button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/* If line-height can't be modified, then fix Firefox spacing with padding */
input[type='button']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
	padding: 0.4em;
}

/* The disabled styles */
button[disabled],
button[disabled]:hover,
input[type='button'][disabled],
input[type='button'][disabled]:hover,
input[type='reset'][disabled],
input[type='reset'][disabled]:hover,
input[type='submit'][disabled],
input[type='submit'][disabled]:hover,
.button-disabled,
.button-disabled:hover {
	background: #eee;
	color: #aaa;
	border-color: #aaa;
	cursor: default;
	text-shadow: none;
	position: static;
	box-shadow: none;
}

/*
*
*	BUTTON SMALL
*	white 25
*
*/
/*
.buttonS {
	background: @cp-color-1;
	height: 40px;
	padding: 0 15px;
	text-shadow: none;
	margin: 0;
	font: 700 14px/30px @headline-font;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	}
.buttonS span {
	display: block;
	padding: 3px 65px 5px 0;
	background: url("@{path}/arrows.png") right -188px no-repeat;
	}
.buttonS:hover,
.buttonS:active,
.buttonS:focus {
	background: #05b8d2;
	color: white;
	text-shadow: none;
	}
*/
.buttonS {
	background: @cp-color-2;
	height: 40px;
	padding: 0 10px;
	text-shadow: none;
	margin: 0;
	font: 400 15px/40px @body-font;
	border-radius: 0;
}

.buttonS span {
	display: block;
	padding: 0 20px 0 0;
	background: url('@{path}/icon_arrow_right_white.svg') right 12px no-repeat;
}

.buttonS:hover,
.buttonS:active,
.buttonS:focus {
	// background: #05b8d2;
	background: black;
	color: white;
	text-shadow: none;
}

/*
*
*	BUTTON MEDIUM
*
*
*/
.buttonM {
	background: @cp-color-2;
	height: 42px;
	padding: 0 15px;
	text-shadow: none;
	margin: 0;
	font: 700 16px/42px @headline-font;
	border-radius: 0;
}

.buttonM span {
	display: block;
	padding: 0 30px 0 0;
	background: url('@{path}/icon_arrow_right_white.svg') right 12px no-repeat;
}

.buttonM:hover,
.buttonM:active,
.buttonM:focus {
	// background: #05b8d2;
	background: black;
	color: white;
	text-shadow: none;
}

/*
*
*	BUTTON LARGE
*
*
*/
.buttonL {
	background: @cp-color-2;
	height: 42px;
	padding: 0 20px;
	text-shadow: none;
	margin: 0;
	font: 700 18px/42px @headline-font;
	border-radius: 0;
}

.buttonL span {
	display: block;
	padding: 0 65px 0 0;
	background: url('@{path}/icon_arrow_right_white.svg') right 12px no-repeat;
}

.buttonL:hover,
.buttonL:active,
.buttonL:focus {
	background: black;
	color: white;
	text-shadow: none;
}

/*
*
*	BUTTON EXTRALARGE
*
*
*/
.buttonXL {
	background: @cp-color-2;
	height: 50px;
	padding: 0 20px;
	text-shadow: none;
	margin: 0;
	font: 700 18px/50px @headline-font;
	border-radius: 0;
	position: relative;
}

.buttonXL span {
	display: block;
	padding: 0 65px 0 0;
}

.buttonXL span::after {
	content: '';
	width: 30px;
	height: 30px;
	border-radius: 15px;
	position: absolute;
	right: 10px;
	top: 10px;
	background: url('@{path}/icon_arrow_right_white.svg') center center no-repeat;
}

.buttonXL:hover,
.buttonXL:active,
.buttonXL:focus {
	background: black;
	color: white;
	text-shadow: none;

	span::after {
		background-image: url('@{path}/icon_arrow_right_white.svg');
	}
}

/*
*
*	BUTTON EXTRAEXTRALARGE
*
*
*/
/*
.buttonXXL {
	background: @cp-color-1;
	height: 60px;
	padding: 0 20px;
	text-shadow: none;
	margin: 0;
	font: 400 24px/60px @headline-font;
	-webkit-border-radius: 0;
	border-radius: 0;
	position: relative;

	display: block;
	float: none;
	text-align: right;
	}
.buttonXXL span {
	display: block;
	padding: 0 50px 0 0;
	}
.buttonXXL span:after {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 20px;
	position: absolute;
	right: 15px;
	top: 10px;
	background: white url("@{path}/icon_arrow_right_blue.svg") center center no-repeat;
	}
.buttonXXL:hover,
.buttonXXL:active,
.buttonXXL:focus {
	background: black;
	color: white;
	text-shadow: none;

	span:after {
		background-image: url("@{path}/icon_arrow_right_black.svg");
		}
	}
*/
.buttonXXL {
	background: @cp-color-2;
	height: 60px;
	padding: 0 30px;
	text-shadow: none;
	margin: 0;
	font: 700 24px/60px @headline-font;
	border-radius: 0;
	position: relative;
}

.buttonXXL span {
	display: block;
	padding: 0 85px 0 0;
}

.buttonXXL span::after {
	content: '';
	width: 40px;
	height: 40px;
	border-radius: 15px;
	position: absolute;
	right: 10px;
	top: 10px;
	background: url('@{path}/icon_arrow_right_white.svg') center center no-repeat;
}

.buttonXXL:hover,
.buttonXXL:active,
.buttonXXL:focus {
	background: black;
	color: white;
	text-shadow: none;

	span::after {
		background-image: url('@{path}/icon_arrow_right_white.svg');
	}
}

a.reset {
	padding: 2px 0 7px 18px;
	display: inline-block;
	position: relative;
	font: 400 14px/20px @body-font;
	color: @text-color;
	border: 0 !important;
	text-decoration: none !important;
	margin-right: 30px;

	.icon-close;

	&::before {
		color: @cp-color-2;
		position: absolute;
		left: 0;
		font-size: 12px;
	}

	&:hover {
		color: @cp-color-2;
	}
}

.button.disabled {
	opacity: 0.5;
	background-color: #000;
	cursor: not-allowed;

	span {
		padding-right: 0;

		&::after {
			display: none;
		}
	}
}
