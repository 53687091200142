.gallery {
	
	.page {
		position: relative;
		margin: 80px 0;
		
		.pagination {
			position: absolute;
			right: 10px;
			margin: 0;
		}
		
		.paginationTop {
			top: -80px;
		}
		.paginationBottom {
			bottom: -80px;
		}
		
	}
		
	.media {
		width: 25%;
		float: left;
		margin: 0;
		padding: 0 0 20px;
		
		.image,
		.caption,
		.actions {
			margin: 0 10px;
		}
		
		.caption {
		}
		
		.actions {
			display: block;
			background: white;
			padding: 0 10px 15px;
			
			a {
				float: left;
				display: block;
				padding: 0 0 0 22px;
				width: 0;
				height: 22px;
				color: @text-color;
				overflow: hidden;
				position: relative;
				margin: 0 5px 0 0;
				
				&:before {
					color: @text-color;
					position: absolute;
				}
				
				&:hover:before {			
					color: @cp-color-2;
				}
			}
			
			.zoom {
				.icon-lupe;
				
				&:before {
					left: 4px;
					top: 2px;
					font-size: 14px;
				}
			}
			
			.download {
				.icon-download;
				
				&:before {
					left: 5px;
					top: 0;
					font-size: 14px;
				}
			}
			
		
		}
		
		
		
		/*
			font: 400 ~"13px/18px" @body-font;
			padding: 5px 10px;
			color: @text-color;
			background: white;
			margin: 0 10px 20px;
	
			p {
				font: 400 ~"13px/18px" @body-font;
				padding: 0;
			}
		}
		*/
	}
	
}