#breadcrumb {
	padding: 0 20px 65px;
	margin-top: -65px;
	}

#breadcrumb ul {
	list-style: none;
	margin: 0;
	}
	
#breadcrumb li {
	list-style: none;
	margin: 0;
	float: left;
	color: @text-color;
	font: 400 ~"12px/20px" @body-font;
	}
#breadcrumb li:before {
	content: "·";
	width: 20px;
	text-align: center;
	display: inline-block;
	color: @text-color !important;
	}
#breadcrumb li:first-child:before {
	display: none;
	}
	
#breadcrumb li a {
	display: inline-block;
	color: @text-color;
	font: 400 ~"12px/20px" @body-font;
	}
#breadcrumb li a:hover {
	color: @cp-color-2;
	}
	
	
	
@media screen and ( max-width: 959px )  {
	
	#breadcrumb {
		display: none;
	}

}