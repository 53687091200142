// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css

@font-face {
	font-family: 'icon';
	src: url('../../statics/icons/icon-ee3ddc9a642605cf98a84ce66bb76204.eot');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'icon';
	src: url('../../statics/icons/icon-ee3ddc9a642605cf98a84ce66bb76204.eot');
	src: url('../../statics/icons/icon-ee3ddc9a642605cf98a84ce66bb76204.eot?#iefix')
			format('embedded-opentype'),
		url('../../statics/icons/icon-ee3ddc9a642605cf98a84ce66bb76204.woff') format('woff'),
		url('../../statics/icons/icon-ee3ddc9a642605cf98a84ce66bb76204.ttf') format('truetype'),
		url('../../statics/icons/icon-ee3ddc9a642605cf98a84ce66bb76204.svg?#icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

// Bootstrap Overrides
[class^='icon-']::before,
[class*=' icon-']::before {
	font-family: 'icon';
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Mixins

.icon-abgelehnt,
.icon-arrow_down,
.icon-arrow_left,
.icon-arrow_right,
.icon-arrow_up,
.icon-check,
.icon-close,
.icon-delete,
.icon-document,
.icon-download,
.icon-edit,
.icon-extern,
.icon-facebook,
.icon-freigegeben,
.icon-google,
.icon-kontakt,
.icon-links,
.icon-loading,
.icon-lupe,
.icon-mail,
.icon-menue,
.icon-navi,
.icon-navi_mobile_close,
.icon-navi_mobile_open,
.icon-pause,
.icon-print,
.icon-twitter,
.icon-ungeprueft,
.icon-view,
.icon-youtube {
	&::before {
		font-family: 'icon';
		display: inline-block;
		font-weight: normal;
		font-style: normal;
		text-decoration: inherit;
	}
}

// Icons

.icon-abgelehnt {
	&::before {
		content: '\f101';
	}
}

.icon-arrow_down {
	&::before {
		content: '\f102';
	}
}

.icon-arrow_left {
	&::before {
		content: '\f103';
	}
}

.icon-arrow_right {
	&::before {
		content: '\f104';
	}
}

.icon-arrow_up {
	&::before {
		content: '\f105';
	}
}

.icon-check {
	&::before {
		content: '\f106';
	}
}

.icon-close {
	&::before {
		content: '\f107';
	}
}

.icon-delete {
	&::before {
		content: '\f108';
	}
}

.icon-document {
	&::before {
		content: '\f109';
	}
}

.icon-download {
	&::before {
		content: '\f10a';
	}
}

.icon-edit {
	&::before {
		content: '\f10b';
	}
}

.icon-extern {
	&::before {
		content: '\f10c';
	}
}

.icon-facebook {
	&::before {
		content: '\f10d';
	}
}

.icon-freigegeben {
	&::before {
		content: '\f10e';
	}
}

.icon-google {
	&::before {
		content: '\f10f';
	}
}

.icon-kontakt {
	&::before {
		content: '\f110';
	}
}

.icon-links {
	&::before {
		content: '\f111';
	}
}

.icon-loading {
	&::before {
		content: '\f112';
	}
}

.icon-lupe {
	&::before {
		content: '\f113';
	}
}

.icon-mail {
	&::before {
		content: '\f114';
	}
}

.icon-menue {
	&::before {
		content: '\f115';
	}
}

.icon-navi {
	&::before {
		content: '\f116';
	}
}

.icon-navi_mobile_close {
	&::before {
		content: '\f117';
	}
}

.icon-navi_mobile_open {
	&::before {
		content: '\f118';
	}
}

.icon-pause {
	&::before {
		content: '\f119';
	}
}

.icon-print {
	&::before {
		content: '\f11a';
	}
}

.icon-twitter {
	&::before {
		content: '\f11b';
	}
}

.icon-ungeprueft {
	&::before {
		content: '\f11c';
	}
}

.icon-view {
	&::before {
		content: '\f11d';
	}
}

.icon-youtube {
	&::before {
		content: '\f11e';
	}
}
