.highlight {
	color: white;
	background: @cp-color-1;
	padding: 20px 20px 40px;
	
	
	.image {
		width: 140px;
		float: left;
		margin: -20px 20px 0 -20px; 
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	
	.text {
		padding: 0;
		overflow: hidden;
	}
	
	.deck {
		font: 700 ~"20px/24px" @headline-font;
		padding: 0 0 10px;
	}
	
	h2 {
		font: 700 36px/40px @headline-font;
		padding: 0 0 15px;
		color: white;
	}

	a {
		color: black;
		border-color: white !important;
	}
	
	.read-more {
		position: absolute;
		right: 0;
		bottom: 0;
		display: block;
		width: 40px;
		height: 40px;
		color: @cp-color-2;
		background: white;
		text-indent: -1000em;
		
		.icon-arrow_right;
		
		&:before {
			position: absolute;
			left: 17px;
			top: 11px;
			font-size: 16px;
			text-indent: 0;
		}
		
		&:hover {
			color: white !important;
			background-color: @cp-color-2 !important;
		}
		
	}
	
}


.highlight.bgYellow {
	color: @text-color;
	h2 {
		color: @text-color;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.read-more {
		color: @cp-color-1;
		text-decoration: none;
	}
}
.highlight.bgRed {
	a {
		text-decoration: underline;
		color: white;
		&:hover {
			text-decoration: none;
		}
	}
	.read-more { 
		color: @cp-color-2;
		text-decoration: none;
	}
}
.highlight.bgPatrol {
	a {
		text-decoration: underline;
		color: white;
		&:hover {
			text-decoration: none;
		}
	}
	.read-more { 
		color: @cp-color-3;
		text-decoration: none;
	}
}
.highlight.bgOrange {
	
	.read-more { 
		color: @cp-color-4;
		text-decoration: none;
	}
}


@media screen and ( max-width: 639px )  {
	
	.highlight {
		margin: 0 10px 40px !important;
		padding: 20px 20px 0 !important;
		
	}
}

@media screen and ( max-width: 479px )  {
	
	.highlight {
		
		.image {
			width: 140px;
			float: none;
			margin: -20px 0 20px -20px;
		}
	}
	
}