#address strong,
#address p {
	padding: 20px;
	width: 100%;
	border-bottom: 1px solid #e6e6e6;
	box-sizing: border-box;
	font: 400 ~"16px/24px" @body-font;
	
	@media screen and (min-width: 959px) {
		border-bottom: 0;	
		float: left;
		padding: 65px 20px;
		width: 33.33%;
		box-sizing: border-box;
		border-right: 1px solid #e6e6e6;
		min-height: 270px;
	}
	
	a {
		color: @text-color;
		
		&:hover, &:focus {
			color: @cp-color-2;
		}
	}
}

#address strong {
	font: 700 ~"16px/24px" @body-font-2;
	border-bottom: 0;
	
	@media screen and (min-width: 959px) {
		border-left: 1px solid #e6e6e6;
	}
}


#footer.footer--exhibition {
	
	@media screen and (max-width: 959px) {
		.wrap{
			/*
			padding: 0;
			width: 100%;
			*/
		}
	}
	
	.address {
		padding: 20px;
		width: 100%;
		border-bottom: 1px solid #e6e6e6;
		box-sizing: border-box;
		font: 400 ~"16px/24px" @body-font;
	
		@media screen and (min-width: 959px) {
			border-bottom: 0;
			padding: 65px 20px;
			border-left: 1px solid #e6e6e6;
			float: left;
			width: 33.33%;
			border-right: 1px solid #e6e6e6;
			min-height: 380px;
		}
		
		strong {
			font: 700 ~"16px/24px" @body-font-2;
			padding-bottom: 20px;
			display: block;	
		}
		
		p {
			font: 400 ~"16px/24px" @body-font;
			padding: 0;
		}
	}
	
	#service {
		padding: 20px;
		border-bottom: 1px solid #e6e6e6;
		
		@media screen and (min-width: 959px) {
			padding: 65px 20px;
			min-height: 380px;
		}
	}
	
	#service li a, 
	#service li .legal-links__link {
		font: 400 ~"16px/24px" @body-font;
	}
	
	.partner {
		padding: 20px;
		width: 100%;
		box-sizing: border-box;
		font: 400 ~"16px/24px" @body-font;
		
		@media screen and (min-width: 959px) {
		float: left;
		padding: 65px 20px 20px;
		width: 33.33%;
		border-right: 1px solid #e6e6e6;
		min-height: 380px;
		}
		.partner__item {
			padding-bottom: 20px;
		}
	}
}