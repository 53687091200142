.image {
	position: relative;
	}
	
.image img {
	display: block;
	height: auto;
	width: 100%;
	}

.image .credits {
	position: absolute;
	font: 400 11px/18px @body-font !important;
	color: white;
	bottom: 0;
	left: 0;
	padding: 1px 10px;
	display: inline-block;
	opacity: .5;
	z-index: 1;
	/*
	text-shadow: 0 0 4px black;
	*/
	background: rgba(0,0,0,.5);
	}
	
.image .credits:hover {
	/*
	text-shadow: 0 0 8px black;
	*/
	opacity: 1;
	background: rgba(0,0,0,1);
	}




.media {
	margin: 0 10px;
	padding: 0 0 40px;
	position: relative;
}
	
.media .image {
	background: @bg-color-2;
}

.media.audio audio {
	display: block;
	width: 100%;
}
	
.media .lightbox {
	position: relative;
	display: block;
	
	i {
		position: absolute;
		right: 0;
		bottom: 0;
		display: block;
		width: 44px;
		height: 44px;
		color: @text-color;
		background: white;
		opacity: .5;
		
		.icon-lupe;
		
		&:before {
			position: absolute;
			left: 13px;
			top: 12px;
			font-size: 16px;
		}
	}
	
	&:hover i {
		opacity: 1;
		background: @cp-color-2;
		color: white;
	}
}

.media .text {
	padding: 10px 20px 0!important;
	}



.media .caption {
	font: 400 ~"15px/22px" @body-font;
	padding: 10px 15px;
	color: @cp-color-gray;
	background: white;
	}
	
.media .caption p {
	font: 400 ~"15px/22px" @body-font;
	padding: 0;
	}



.mediaLeft {
	float: left;
	width: 300px;
	padding: 5px 20px 10px 0;
	margin: 0 0 0 10px;
	}

.mediaRight {
	float: right;
	width: 300px;
	padding: 5px 0 10px 20px;
	margin: 0 10px 0 0;
	}

.mediaLeft .image,
.mediaRight .image {
	padding: 0;
	}
	
.mediaLeft .caption,
.mediaRight .caption {
	padding: 10px 15px;
	}




	/*

.boxA .media {
	width: 590px;
	margin: 0 0 0 -30px;
	}
	
.boxA .mediaLeft {
	width: 220px;
	margin: 0 0 0 -30px;
	}

.boxA .mediaRight {
	width: 220px;
	margin: 0 -30px 0 0;
	}
	
.boxA .media .caption {
	padding: 5px 0 5px 30px;
	}
	
.boxA .mediaRight .caption {
	padding-left: 0;
	}

	*/





.aside .mediaLeft,
.aside .mediaRight {
	float: none;
	width: 300px;
	padding: 0 0 20px;
	margin:0 10px;
	}


@media screen and ( min-width: 960px )  {


	.media {
		margin: 0 20px;
	}
	
	.mediaLeft {
		width: 340px;
		padding: 5px 40px 20px 0;
		margin: 0 0 0 20px;
		}
	
	.mediaRight {
		padding: 5px 0 20px 40px;
		width: 340px;
		margin: 0 20px 0 0;
		}
	
	
	.aside .mediaLeft,
	.aside .mediaRight {
		width: 320px;
		margin:0 20px;
	}	
}
	/*

.aside .boxA .media {
	width: 300px;
	margin:0 -30px;
	}

.aside .boxA .media .caption {
	padding: 5px 30px 0;
	}


.aside .boxB .media {
	width: 300px;
	margin:0 -20px;
	}

.aside .boxB .media .caption {
	padding: 5px 20px 0;
	}


.aside .boxC .media {
	width: 300px;
	margin:0 -15px;
	}

.aside .boxC .media .caption {
	padding: 5px 15px 0;
	}
	
	*/
	
	
	
@media screen and ( max-width: 639px )  {
	
	.media {
		width: auto;
		}
		
	.mediaLeft { 
		width: 220px;
		}
	
	.mediaRight {
		width: 220px;
		}
		
}

@media screen and ( max-width: 479px )  {
	
		
	.mediaLeft,
	.mediaRight {
		width: auto;
		margin: 0 10px 20px;
		padding: 0;
		float: none;
		}
}