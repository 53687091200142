@media all {
	label,
	.label {
		position: relative;
		line-height: @body-font-line-height;
		display: block;
	}

	.radio label,
	.checkbox label {
		display: inline;
	}

	input,
	textarea {
		cursor: text;
	}

	.rdio input,
	.checkbox input,
	input[type='image'],
	input[type='radio'],
	input[type='checkbox'],
	select,
	label {
		cursor: pointer;
	}

	textarea {
		overflow: auto;
	}

	input.hidden,
	input[type='hidden'] {
		display: none !important;
	}

	.radio input:focus,
	.radio input:hover,
	.radio input:active,
	.checkbox input:focus,
	.checkbox input:hover,
	.checkbox input:active,
	input[type='radio']:focus,
	input[type='radio']:hover,
	input[type='radio']:active,
	input[type='checkbox']:focus,
	input[type='checkbox']:hover,
	input[type='checkbox']:active {
		border: @table-border;
	}

	input,
	textarea,
	select {
		display: block;
		position: relative;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
	}

	.radio input,
	.checkbox input,
	input[type='radio'],
	input[type='checkbox'] {
		display: inline;
		margin-left: 0;
		margin-right: 0.5ex;
		width: auto;
		height: auto;
	}

	input[type='image'] {
		border: 0;
		display: inline;
		height: auto;
		margin: 0;
		padding: 0;
		width: auto;
	}

	label,
	.label {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.form .submit input {
		display: inline;
		overflow: visible;
		width: auto;
	}
}

// styling

@media screen {
	.form {
		margin: 0 0 @content-margin-bottom 0;
	}

	fieldset {
		position: relative;
		background: transparent;
	}

	legend {
		background: transparent;
		line-height: @body-font-line-height;
		font-weight: normal;
	}

	label,
	.label {
		color: @body-font-color;
		line-height: @body-font-line-height;
	}

	.form .item {
		padding: 0;
		margin: 0 0 @form-margin-bottom 0;
	}

	.form .item:last-child {
		margin-bottom: 0;
	}

	.form .checkbox:focus + label {
		color: #000;
	}

	input,
	textarea,
	select {
		border: @form-border;
		line-height: @body-font-line-height;
		background: @form-bg;
		color: @form-font-color;
	}

	input,
	textarea {
		padding: (@form-padding - 2);
	}

	select {
		padding: (@form-padding - 3) (@form-padding - 3) (@form-padding - 3) (@form-padding - 3);
	}

	input:focus,
	select:focus,
	textarea:focus,
	input:hover,
	select:hover,
	textarea:hover,
	input:active,
	select:active,
	textarea:active {
		border: @form-border-hover;
		background: @form-bg;
	}

	optgroup {
		font-style: normal;
		font-weight: bold;
	}

	.checkbox input,
	input[type='image'],
	input[type='radio'],
	input[type='checkbox'] {
		border: 0 none !important;
		background: @form-bg !important;
	}

	input:valid,
	textarea:valid {
		background: @form-bg;
	}

	.required,
	input:invalid,
	textarea:invalid {
		background-color: @form-bg;
	}
}
