.facts {
	background: white;
	width: ~"calc(100% - 20px)" !important;
	padding: 20px 20px 30px;
}

.facts h2 {
	/*
	border-top: 5px solid @text-color;
	padding: 5px 0;
	font: 400 ~"20px/24px" @headline-font;
	text-transform: uppercase;
	*/
	padding: 0 0 20px;
	font: 700 24px/32px @headline-font;
	}
/*
.facts h3 {
	font: 400 16px/22px @headline-font;
	padding: 0 0 10px;
	}
*/

.facts p {
	font: 400 15px/22px @body-font;
	padding: 0 0 20px;
	}
	
.facts h2 + p {
	margin-top: -15px;
	}

.fact {
	padding: 15px 0;
	border-top: 1px solid #d3d2d3;
	}

.fact h4 {
	font: 700 18px/24px @headline-font;
	float: left;
	width: 30%;
	padding: 0;
	margin: 0;
	}

.fact .text {
	overflow: hidden;
	padding-bottom: 0;
}

.fact p {
	padding: 0;
	font: 400 15px/22px @body-font;
	}

.fact ol,
.fact ul {
	margin-bottom: 0;
	}
	
.fact li {
	font: 400 15px/22px @body-font;
	padding-bottom: 10px;
	}
	
.fact li:last-child {
	padding-bottom: 0;
	}
	
	



@media screen and ( min-width: 960px )  {

	.bloc-320 {
	
		.facts {
			
			.fact {
				padding: 15px 0;
	
				
				h4 {
					width: 100%;
					float: none;
					padding-bottom: 5px;
				}
			}
		}
	}
}

	
	
	
@media screen and ( max-width: 639px )  {
	
	
	.fact {
		
		h4 {
			width: 100%;
			float: none;
			padding-bottom: 5px;
		}
	}
}



@media screen and ( min-width: 960px )  {
	
	.facts {
    	width: ~"calc(100% - 40px)" !important;
	}
	
	.fact {
		padding: 20px 0;
	}
}
