#logo {
	background: url("@{path}/logo.svg") left top no-repeat;
	background-size: 100% auto;
	display: block;
	width: 242px;
	height: 104px;


	width: 222px;
	height: 96px;
	
	position: absolute;
	left: 10px;
	top: 30px;
	
	text-indent: -10000em;
	
	
	span {
		/*
		display: block;
		background: rgba(255,255,255,.05);
		font: 400 14px/16px Arial;
		position: absolute;
		left: 0;
		bottom: 0;
		color: @text-color;
		letter-spacing: -0.02em;
		background: white;
	
		font: 400 14px/16px @body-font;
		letter-spacing: -0.01em;
		*/
	}
}

@media screen and ( max-width: 639px )  {
	
	#logo {
		background-size: 75% auto;
		top: 22px;
		
	}
	
}

@media screen and ( max-width: 479px )  {

	
	
	
}