.back {
	margin: 0 10px 40px;
	}

.back a {
	padding: 0 0 0 22px;
	font: 400 14px/20px @body-font;
	display: inline-block;
	position: relative;
	color: @text-color;
	
	.icon-arrow_left;

	&:before {
		//color: @cp-color-2;
		font-size: 14px;
		position: absolute;
		left: 4px;
	}
	
	&:hover {
		color: @cp-color-2;
	}
	
}

span.back {
	top: -22px;
	position: relative;
	margin: 0 0 0 10px;
}
	
.backBottom {
	padding: 5px 0 0;
	border-top: 1px solid #d3d2d3;
	}
	
.backTop {
	padding: 0 0 5px;
	border-bottom: 1px solid #d3d2d3;
	}

.back-last-child {
	top: -22px;
	margin-bottom: 18px !important;
	float: left;
}

@media screen and ( max-width: 639px )  {

	.back {
		margin-top: 15px;
		margin-bottom: 20px;
	}

	span.back {
		position: static;
	}
}